// ** JWT Service Import
import JwtService from './jwtService';

// ** Export Service as useJwt
export default function useJwt() {
  const jwt = new JwtService();

  return {
    jwt,
  };
}

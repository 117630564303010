import moment from 'moment';
import dayjs, { type Dayjs } from 'dayjs';

export function CheckForValidDateAndReturnFormat(dateTime: string | Date | Dayjs, format: string) {
  if (!dateTime) {
    return null;
  }

  let parsed = dayjs(dateTime, ['DD-MM-YYYY', 'DD/MM/YYYY'], true);

  if (!parsed.isValid()) {
    parsed = dayjs(dateTime, undefined, true);
  }

  if (parsed.isValid()) {
    return parsed.format(format);
  }
  return null;
}

export function CheckForValidDateAndRetunDayjs(dateTime: string | Date | Dayjs): Dayjs | null {
  if (!dateTime) {
    return null;
  }

  let parsed = dayjs(dateTime, ['DD-MM-YYYY', 'DD/MM/YYYY'], true);

  if (!parsed.isValid()) {
    parsed = dayjs(dateTime, undefined, true);
  }

  return parsed;
}

export function GetDateWithoutTime(dateTime: Date) {
  return moment(dateTime)
    .local()
    .format('DD-MM-YYYY');
}

export function GetDateStringWithoutTime(dateString: string) {
  return moment(dateString)
    .local()
    .format('DD-MM-YYYY');
}

export function GetDateWithTime(dateTime: Date) {
  const momentDate = moment(dateTime);

  if (!momentDate.isValid()) {
    return '-';
  }

  return momentDate.format('MMMM Do YYYY, h:mm:ss');
}

export function GetDateWith24hTime(dateTime: Date) {
  const momentDate = moment(dateTime);

  if (!momentDate.isValid()) {
    return '-';
  }

  return momentDate.format('MMMM Do YYYY, HH:mm:ss');
}

export function FormatDateWithoutCurrentYear(dateTime: Date) {
  const momentDate = moment(dateTime);

  if (!momentDate.isValid()) {
    return '-';
  }

  const currentYear = moment().year();

  if (momentDate.year() === currentYear) {
    // If the date is from the current year, don't include the year and seconds in the format
    return momentDate.format('MMMM Do, HH:mm');
  }
  // If the date is from a different year, include the year but not the seconds
  return momentDate.format('MMMM Do YYYY, HH:mm');
}


// Input: 40 11,04 * * 0,1,2,3,4,5,6
// Output: [11:40, 04:40]
export function ParseCronTimes(cronExpression: string) {
  const parts = cronExpression.split(' ');
  const minutes = parts[0];
  const hoursPart = parts[1];
  const hours = hoursPart.split(',');

  return hours.map(hour => `${hour.padStart(2, '0')}:${minutes}`);
}
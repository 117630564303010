import { type FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getUserData } from '../../../utility/Utils';
import routeNames from '../../../routing/routeNames';

const Error401: FC = () => {
  const userData = getUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData == null) {
      navigate(routeNames.login);
    }
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid p-10">
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('../media/illustrations/sketchy-1/18.png')}
          alt=""
          className="mw-100 mb-10 h-lg-450px"
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className="fw-bold mb-10" style={{ color: '#A3A3C7', }}>
          Seems there is nothing here
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
        <Link to="/" className="btn btn-primary">
          Return Home
        </Link>
        {/* end::Link */}
      </div>
    </div>
  );
};

export { Error401 };

import Swal from 'sweetalert2';

const regularSweetAlert = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
  },
  buttonsStyling: false,
});

const warningSweetAlert = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger me-50',
    cancelButton: 'btn btn-light',
  },
  buttonsStyling: false,
});

const Message = async (title: string, message: string, type: string, customData?: any) => {
  if (type === 'warning') {
    return await warningSweetAlert.fire({
      heightAuto: false,
      title,
      html: message,
      icon: 'warning',
      ...customData,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
  } if (type === 'warningNoBtns') {
    return await warningSweetAlert.fire({
      heightAuto: false,
      title,
      html: message,
      icon: 'error',
      ...customData,
    });
  }
  return await regularSweetAlert.fire({
    heightAuto: false,
    title,
    html: message,
    icon: type,
    ...customData,
  });
};

const RtkErrorMessage = async (title: string, error: any) => {
  if (error.status === 'FETCH_ERROR') {
    await Message('No connection', 'Failed to fetch', 'error', {});
    return;
  }

  if ('status' in error) {
    if (error.data === null) {
      await Message(title, 'Error', 'error', {});
    }

    let errMsg = 'error' in error ? error.error : JSON.stringify(error.data.messages);

    if (!errMsg) {
      errMsg = error.data.exception;
    }
    await Message(title, `${errMsg}`, 'error', error.data.exception);
  } else {
    await Message(title, `${error.data.exception}`, 'error', {});
  }
};

export { Message, RtkErrorMessage };

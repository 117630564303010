/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import FormButton from '../../components/forms/AppFormButton';
import AppTextInput from '../../components/forms/AppTextInput';
import useJwt from '../../../auth/jwt/useJwt';
import { Message } from '../../../utility/notifications/Message';
import AppNotification from '../../../utility/notifications/AppNotifications';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

export function ForgotPassword () {
  const [loading] = useState(false);
  const navigate = useNavigate();

  const { jwt, } = useJwt();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async () => { },
  });

  const handleSubmit = async (data: any) => {
    jwt
      .forgotPassword(data.email)
      .then((response: any) => {
        if (response.status === 200) {
          AppNotification.success(
            'Password reset',
            'If an account is linked to the email adress, you will receive an email'
          );

          navigate('/auth/login');
        }
      })
      .catch((error: any) => {
        Message('Password reset', error.response.data.exception, 'error', {});
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleSubmit}>
      {({ handleSubmit, }) => (
        <form
          className="form w-100 h-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_signin_form"
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="text-center mb-10">
                <h1 className="mb-3">Forgot password</h1>
              </div>

              {formik.status
? (
  <div className="mb-lg-15 alert alert-danger">
    <div className="alert-text font-weight-bold">{formik.status}</div>
  </div>
              )
: (
  <div className="mb-10 bg-light-info p-8 rounded">
    <div className="">
      A link will be sent to your
      {' '}
      <strong> email</strong>
      {' '}
      to activate your email
      address. If you don't get an email within a few minutes, please re-try.
    </div>
  </div>
              )}

              <div className="fv-row mb-10">
                <AppTextInput type="email" name="email" placeholder="Email" label="Email address" />
              </div>
            </div>

            <div className="d-grid align-items-end ">
              <div className="mb-5">
                <FormButton
                  type="submit"
                  text="Submit"
                  color="primary"
                  state={loading}
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={handleSubmit}
                />
              </div>

              <div className="">
                <FormButton
                  type="button"
                  text="Go back"
                  color="secondary"
                  state={false}
                  disabled={false}
                  onClick={() => { navigate('/auth/login'); }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

import { useState } from 'react';

export interface ITenantCardProps {
  name: string;
  image: string;
  favourite: boolean;
  tenantId: number;
  onClick: () => void;
  onCardClick: (a: number) => void;
  isLoading: boolean;
}

export default function TenantCard(props: ITenantCardProps) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className="card shadow-sm mx-3 my-4 min-h-250px h-250px min-w-200px w-200px w-50 w-md-150px animate__animated card-animation-zoom overlay overlay-block"
      onClick={() => { props.onCardClick(props.tenantId); }}>
      <div
        className="card-body p-0 mx-4 d-flex flex-column align-content-center h-180px  justify-content-center">
        <img className="card-img-top" src={`data:image/jpg;base64, ${props.image}`} alt="" />
      </div>
      <div className="card-header bg-secondary p-2 ">
        <div className="row align-items-center justify-content-between w-100">
          <h3 className="col-10 text-truncate">
            {props.name}
          </h3>

          <button type="button" className="btn col-1 p-0"
            onMouseEnter={() => { setIsShown(true); }}
            onMouseLeave={() => { setIsShown(false); }}
            onClick={(event) => {
              event.stopPropagation();
              props.onClick();
            }}
          >
            {
              props.favourite !== isShown
                ? <i className="las la-star text-warning fs-1"></i>
                : <i className="lar la-star text-warning fs-1"></i>
            }
          </button>
        </div>
      </div>

      {props.isLoading &&
        <div className="overlay-layer rounded bg-dark bg-opacity-50">
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </div>
  );
}

import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useListView } from '../../TenantListView';
import { useGetImpersonateTenantUsersQuery } from '../../../user-management/api/apiUser';
import AppTable from '../../../components/table/AppTable';
import { impersonateColumns } from './_collumns';
import { type GetRequestFilterTenant } from '../../../../types';
import { requestFilter } from '../../../../utility/tables/defaultRequestFilter';
import { SearchComponent } from '../../../components/inputs/SearchComponent';

const ImpersonateModalWrapper: React.FC = () => {
  const { impersonateId, } = useListView();

  const [paginationState, setPaginationState] = useState<GetRequestFilterTenant>({
    ...requestFilter,
    sorting: 'Name asc',
    tenantId: impersonateId,
  });

  const handleSearch = (e: string) => {
    setPaginationState({
      ...paginationState, keyWord: e, currentPage: 1, skipCount: 0,
    });
  };

  const { impersonateToggle, } = useListView();

  const { data, isLoading, } = useGetImpersonateTenantUsersQuery(paginationState);

  return (
    <Modal
      size="xl"
      tabIndex={-1}
      aria-hidden="true"
      show
      dialogClassName="modal-dialog modal-dialog-centered"
      onHide={() => { impersonateToggle(null); }}
      contentClassName="bg-transparent"
    >
      <div className="modal-content">
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <h2> Select a user</h2>
        </Modal.Header>
        <div className="modal-body scroll-y">
          <div className="pb-5">
            <SearchComponent placeholder="Search users" onChange={handleSearch} />
          </div>
          {isLoading && (
            <div className="card-body d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {data && (
            <AppTable
              totalCount={data.data.totalCount}
              paginationState={paginationState}
              data={data.data.items}
              columns={impersonateColumns}
              setPaginationState={setPaginationState}
            />
          )}
          <div className="text-end pt-5 d-flex flex-row-reverse justify-content-between">

            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => { impersonateToggle(null); }}
              disabled={false}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ImpersonateModalWrapper };

import { useState } from 'react';
import {
  FieldArray, type FieldArrayRenderProps, type FormikValues, useFormikContext
} from 'formik';
import {
  closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Accordion } from 'react-bootstrap';
import { type AccordionEventKey } from 'react-bootstrap/AccordionContext';
import AppFormError from '../../components/forms/AppFormError';
import { objectByString } from '../../../utility/Utils';
import { SortableParameterItem } from './SortableParameterItem';
import { SortableImportFormItem } from './SortableImportFormItem';

interface Props {
    name: string;
    values: FormikValues;
    nestedName: string;
    isImportForm?: boolean;
    dataType: Map<number, string>;
}

function ParameterFieldArray ({
  name, values, nestedName, dataType, isImportForm = false,
}: Props) {
  const [activeAccordion, setActiveAccordion] = useState(['']);
  const { errors, } = useFormikContext();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAccordionClick = (event: AccordionEventKey) => {
    if (!event) return;
    const exists = activeAccordion.includes(event.toString());
    if (exists) {
      setActiveAccordion(activeAccordion.filter((c) => c !== event));
    } else {
      setActiveAccordion((activeAccordion) => [...activeAccordion, event.toString()]);
    }
  };

  function closeAllTheAccordionItems () {
    setActiveAccordion([]);
  }

  function handleDragEnd (event: any, parameterArrayHelper: FieldArrayRenderProps) {
    const { active, over, } = event;
    parameterArrayHelper.move(active.id - 1, over.id - 1);
  }

  if (values[name] === undefined) {
    return null;
  }

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(e) => { handleDragEnd(e, arrayHelpers); }}
          onDragStart={closeAllTheAccordionItems}
        >
          <SortableContext
            items={values[name] ? values[name].map((a: any, i: any) => i + 1) : []}
            strategy={verticalListSortingStrategy}
          >
            <div>
              {values[name].map((field: any, index: number) => (
                <Accordion
                  key={field.sortId ? field.sortId : field.id}
                  activeKey={activeAccordion}
                  onSelect={(event) => { handleAccordionClick(event); }}
                >
                  {isImportForm
                    ? (
                      <SortableImportFormItem
                        id={index + 1}
                        importFormField={field}
                        index={index}
                        renderProp={arrayHelpers}
                        name={name}
                        nestedName={nestedName}
                        values={values}
                        dataType={dataType}
                      />
                    )
                    : (
                      <SortableParameterItem
                        key={field.sortId}
                        name={name}
                        nestedName={nestedName}
                        powerBiFormField={field}
                        id={index + 1}
                        index={index}
                        renderProp={arrayHelpers}
                        values={values}
                        dataType={dataType}
                      />
                    )}
                </Accordion>
              ))}
            </div>

            <div
              className="mb-5"
              onClick={() => {
                if (isImportForm) {
                  arrayHelpers.push({
                    name: '',
                    columnIdentifier: 'String1',
                    sortId: new Date().valueOf(),
                    sorting: 0,
                    dataType: 1,
                    defaultValues: [],
                  });
                } else {
                  arrayHelpers.push({
                    name: '',
                    dataType: 1,
                    sortId: new Date().valueOf(),
                    values: [],
                  });
                }
                setActiveAccordion((activeAccordion) => [...activeAccordion, (values[name].length).toString()]);
              }}
            >
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                htmlFor="kt_create_account_form_account_type_personal"
              >
                <span className="svg-icon svg-icon-3x me-5">
                  <span className="svg-icon svg-icon-1">
                    <i className="bi bi-plus fs-2x " />
                  </span>
                </span>
                <span
                  className="text-dark fw-bolder d-block fs-4"
                >
                  {isImportForm ? 'Add field' : 'Add parameters' }
                </span>
              </label>
            </div>

            {/* @ts-expect-error */}
            {typeof errors.fields === 'string'
              ? (
                <AppFormError
                  visible={objectByString(errors, name)}
                  error={objectByString(errors, name)}
                />
              )
              : null}

          </SortableContext>
        </DndContext>
      )}
    />
  );
}

export { ParameterFieldArray };

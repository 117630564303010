import React from 'react';
import { type AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Container, type ContainerProps } from '../../content-manager/view-group/components';
import { type ViewGroup } from '../../content-manager/view-group/api/types';

const animateLayoutChanges: AnimateLayoutChanges = (args) => (args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true);

export function DroppableViewGroup ({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  onValueChange,
  ...props
}: ContainerProps & {
    disabled?: boolean;
    id: string;
    items: ViewGroup[];
    style?: React.CSSProperties;
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
    },
    animateLayoutChanges,
  });

  return (
    <Container
      onValueChange={onValueChange}
      ref={disabled ? undefined : setNodeRef}
      key={id}
      id={id}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>

  );
}

import { toAbsoluteUrl } from '../../../../_metronic/helpers';

export function LoginHeader () {
  return (

    <div className="d-flex flex-column justify-content-between h-100">

      <div className="" />

      <img alt="Logo" src={toAbsoluteUrl('/media/logos/Logo_BusinessInsight_Light.png')} className="w-100" />

      <div className="w-100  d-none d-lg-block d-flex ">
        <img alt="Logo" src={toAbsoluteUrl('/media/logos/Powered_By_Sincerius.png')} className="w-50" />
      </div>

    </div>
  );
}

import { type GetRequestFilter, GetRequestFilterTenant } from '../../types';

export const requestFilter: GetRequestFilter = {
  currentPage: 1,
  totalItemCount: 0,
  totalAmountOfPages: 0,
  itemsPerPage: 10,
  maxResultCount: 10,
  keyWord: '',
  skipCount: 0,
  sorting: '',
};

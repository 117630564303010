import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import clsx from 'clsx';
import { Handle, Remove } from '../../content-manager/view-group/components';

export interface Props {
  id?: string;
  onValueChange?: (id: string, value: string) => void;
  children: React.ReactNode;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  editMode?: boolean;

  onClick?: () => void;

  onRemove?: () => void;
}

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      onValueChange,
      id,
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      editMode,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    }: Props,
    ref
  ) => {
    const [value, setValue] = useState<string>(label ?? '');

    const setName = (targetValue: string) => {
      if (onValueChange && id) {
        setValue(targetValue);
        onValueChange(id, targetValue);
      }
    };

    return (
      <div
        {...props}
        ref={ref}
        style={
          {
            ...style,
            minHeight: 200,
          } as React.CSSProperties
        }
        className={classNames(
          'flex-column shadow mb-10 bg-gray-100',
          clsx(editMode && 'bg-blue-200')
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        <div className="d-flex p-2 pe-5 align-items-center justify-content-between bg-white border">
          {editMode
? (
  <input
    type="text"
    className="form-control w-250px"
    value={value}
    onChange={(e) => { setName(e.target.value); }}
            />
          )
: (
  <h4 className="m-4">{label}</h4>
          )}
          <div>
            {onRemove && !editMode ? <Remove onClick={onRemove} /> : undefined}
            {editMode && <Handle {...handleProps} />}
          </div>
        </div>
        {placeholder
? (
          children
        )
: (
  <ul className="flex-wrap flex-row list-unstyled">{children}</ul>
        )}
      </div>
    );
  }
);

import { useEffect, useState } from 'react';
import {
  type Column, type ColumnInstance, type Row, useExpanded, useSortBy, useTable
} from 'react-table';
import clsx from 'clsx';
import { type ItemsPerPage } from '../../../types';
import { Pagination } from '../pagination/Pagination';
import { CustomRow } from './CustomRow';

interface Props {
  col?: ReadonlyArray<Column<any>>;
  columns?: any;
  data: any[];
  totalCount: number;
  paginationState: any;
  setPaginationState: (state: any) => void;
  showPagination?: boolean;
  isLoading?: boolean;
  renderRowSubComponent?: (row: Row<any>) => JSX.Element;
  isNestedTable?: boolean;
  dark?: boolean;
  fullHeight?: boolean;
}

function AppTable({
  columns,
  data,
  totalCount,
  paginationState,
  setPaginationState,
  showPagination = true,
  isLoading = false,
  isNestedTable = false,
  renderRowSubComponent,
  dark = false,
  fullHeight = false,
}: Props) {
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
  const {
    getTableProps,
    getTableBodyProps,
    flatHeaders,
    rows,
    prepareRow,
    state: { sortBy, expanded, },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: isNestedTable,
      manualSortBy: true,
      initialState: {
        expanded: expandedRowIds.reduce((acc: any, rowId) => {
          acc[rowId] = true;
          return acc;
        }, {}),
      },
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    // Capture expanded row IDs whenever they change
    const currentExpandedRowIds = Object.keys(expanded).map(Number).filter((rowId) => expanded[rowId]);
    setExpandedRowIds(currentExpandedRowIds);
  }, [expanded]);

  useEffect(() => {
    if (!sortBy || sortBy.length < 1) return;

    const paginationObject = { ...paginationState, };

    paginationObject.sorting = `${sortBy[0].id} ${sortBy[0].desc ? 'desc' : 'asc'}`;
    setPaginationState(paginationObject);
  }, [sortBy]);

  const calculateSkipCount = (currentPage: number, pageSize: number) => {
    if (currentPage === 0) return 0;

    return (currentPage - 1) * pageSize;
  };

  const handlePageChange = (pageNumber: number) => {
    const paginationObject = { ...paginationState, };
    paginationObject.currentPage = pageNumber;
    paginationObject.skipCount = calculateSkipCount(
      pageNumber,
      paginationObject.maxResultCount || 0
    );

    setPaginationState(paginationObject);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const paginationObject = { ...paginationState, };
    paginationObject.maxResultCount = pageSize as ItemsPerPage;
    paginationObject.currentPage = 1;

    paginationObject.skipCount = calculateSkipCount(paginationObject.currentPage, pageSize);

    setPaginationState(paginationObject);
  };

  return (
    <>
      <div className="overflow-auto">
        <div className={clsx('', isLoading && 'overlay w-100 h-20')} style={{ height: fullHeight ? '100%' : '54vh', }}>
          <table className="table table-fixed align-middle gx-5 no-footer table-row-bordered" {...getTableProps()}>
            <thead className={clsx('bg-gray-200', dark && 'bg-primary')} style={{
              position: 'sticky', top: '0', zIndex: isNestedTable ? '0' : '1'
            }}>
              <tr className={clsx('text-start fw-bold fs-7 text-uppercase gs-0', dark ? 'text-white' : 'text-primary')}>
                {flatHeaders.map((column: ColumnInstance<any>) => (
                  <th style={{ width: '150px', }} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div>
                      <span>{column.render('Header')}</span>
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-gray-800 " {...getTableBodyProps()}>
              {rows.length > 0
                ? (
                  rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        renderRowSubComponent={renderRowSubComponent}
                      />
                    );
                  })
                )
                : (
                  <tr>
                    <td colSpan={20}>
                      <div
                        className="d-flex text-center w-100 align-content-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>

          {isLoading &&
            (
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
        </div>
      </div>
      {showPagination && (
        <Pagination
          totalItemCount={totalCount}
          rowsPerPage={paginationState.maxResultCount || 10}
          onPageChange={handlePageChange}
          onMaxPageSizeChange={handlePageSizeChange}
          currentPage={paginationState.currentPage}
        />
      )}
    </>
  );
}
export default AppTable;

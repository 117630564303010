import { type FC } from 'react';
import { useDeleteTenantMutation, usePatchTenantStatusMutation } from '../api/api';
import AppNotification from '../../../utility/notifications/AppNotifications';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import AppFormButton from '../../components/forms/AppFormButton';
import { useListView } from '../TenantListView';

interface Props {
  isActive: boolean;
  tenantName: string;
};

const TenantSetStatusButton: FC<Props> = ({ isActive, tenantName, }) => {
  const { id, toggle, } = useListView();
  const [patchTenantStatus, { isLoading, }] = usePatchTenantStatusMutation();
  const [deleteTenant, { isLoading: DeleteLoading, }] = useDeleteTenantMutation();

  if (!id) { return null; }

  const setTenantStatus = async () => {
    await patchTenantStatus({
      id,
      isEnabled: !isActive,
    })
      .unwrap()
      .then(() => {
        toggle(null);
        AppNotification.success(isActive ? 'Company is disabled' : 'Company is re-enable');
      })
      .catch(async (error) => { await RtkErrorMessage('Deleting company failed', error); });
  };

  const handleDelete = async () => {
    await deleteTenant({
      id,
    })
      .unwrap()
      .then(() => {
        toggle(null);
        AppNotification.success('Company management', `Company ${tenantName} is successfully deleted`);
      })
      .catch(async (error) => { await RtkErrorMessage('Deleting company failed', error); });
  };

  return (
    <div className='d-flex gap-2'>
      {!isActive &&
        <AppFormButton
          width={'w-20'}
          type='button'
          text='Delete'
          state={DeleteLoading}
          color='danger'
          disabled={DeleteLoading}
          onClick={handleDelete}
          popConfirm={true}
          title={`Are you sure you want to delete ${tenantName}?`}
        />
      }

      <AppFormButton
        width={'w-20'}
        type='button'
        state={isLoading}
        popConfirm={true}
        title={`Are you sure you want to ${isActive ? 'disable' : 're-enable'} ${tenantName}?`}
        text={isActive ? 'Disable' : 'Re-enable'}
        onClick={setTenantStatus}
        color={isActive ? 'warning' : 'primary'}
        disabled={false}
      />
    </div>
  );
};

export default TenantSetStatusButton;

export const SET_BOOLEAN = 'SET_BOOLEAN';

export const setBoolean = (value) => ({
  type: SET_BOOLEAN,
  payload: value,
});

const initialState = {
  booleanValue: false,
};

/* This reducer stores the boolean value of whether the user is impersonating from the content manager page
so we can determine whether to go back towards the content manager page or the dashboard page */
const backToContentManagerPage = (state = initialState, action) => {
  switch (action.type) {
  case SET_BOOLEAN:
    return {
      ...state,
      booleanValue: action.payload,
    };
  default:
    return state;
  }
};

export default backToContentManagerPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import FormButton from '../../components/forms/AppFormButton';
import AppTextInput from '../../components/forms/AppTextInput';
import useJwt from '../../../auth/jwt/useJwt';
import { Message } from '../../../utility/notifications/Message';
import { PasswordRequirements } from '../../../constants/password';
import AppNotification from '../../../utility/notifications/AppNotifications';

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .label('Password')
    .test('empty-check', PasswordRequirements.label, (password) => {
      if (password) {
        const schema = Yup.string()
          .matches(PasswordRequirements.expression, PasswordRequirements.label)
          .label('Password');
        return schema.isValidSync(password);
      }
      return true;
    })
    .label('Password'),
  repeatedPassword: Yup.string()
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    })
    .required('This field is required'),
});

export function ResetPassword () {
  const location = useLocation();
  const { token, } = useParams();
  const showActivateAccountTitle = location.pathname.includes('activate-account');

  const [loading] = useState(false);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const initialValues = {
    token: token || '',
    password: '',
    repeatedPassword: '',
  };

  const { jwt, } = useJwt();

  const formik = useFormik({
    initialValues,
    validationSchema: resetSchema,
    onSubmit: async () => { },
  });

  const handleSubmit = async (data: any) => {
    jwt
      .resetPassword(data)
      .then(async (response: any) => {
        if (response.status === 200) {
          AppNotification.success('Password reset', 'your password has been reset successfully');

          navigate('/');
        }
      })
      .catch((error: any) => {
        Message('Log in failed!', error.response.data.exception, 'error', {});
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={resetSchema} onSubmit={handleSubmit}>
      {({ handleSubmit, }) => (
        <form className="form w-100 h-100" onSubmit={formik.handleSubmit} noValidate>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="text-center mb-10">
                {showActivateAccountTitle
? (
  <h1 className="mb-3">Activate your account</h1>
                )
: (
  <h1 className="mb-3">Reset password</h1>
                )}
              </div>

              <div className="fv-row mb-10">
                <AppTextInput
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="password"
                  label="Password"
                  appendIcon={passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                  onClick={() => { setPasswordVisible(!passwordVisible); }}
                />
              </div>

              <div className="fv-row mb-10">
                <AppTextInput
                  type="password"
                  name="repeatedPassword"
                  placeholder="Repeat password"
                  label="Repeat password"
                />
              </div>
            </div>

            <div className="d-grid align-items-end ">
              <div className="mb-5">
                <FormButton
                  type="submit"
                  text="Submit"
                  color="primary"
                  state={loading}
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={handleSubmit}
                />
              </div>

              <div className="">
                <FormButton
                  type="button"
                  text="Go back"
                  color="secondary"
                  state={loading}
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => { navigate('/'); }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

import * as React from 'react';
import { useFormikContext } from 'formik';
import { type ChangeEvent } from 'react';
import { objectByString } from '../../../utility/Utils';

interface AppTextInputProps {
    name: string;
    label?: string;
    title?: string;
    description?: string;
    required?: boolean;
    placeholder?: string;
    appendIcon?: string;
    onClick?: (event: ChangeEvent<HTMLInputElement>) => void;

    [x: string]: any;
}

function AppCheckInput ({
  name,
  label,
  placeholder,
  required = false,
  appendIcon,
  onClick,
  title,
  description,
  ...otherProps
}: AppTextInputProps) {
  const { setFieldValue, values, } = useFormikContext();
  return (
    <div className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        id="flexCheckDefault"
        type="checkbox"
        name={name}
        checked={objectByString(values, name)}
        onChange={(event) => {
          if (onClick) {
            onClick(event);
          } else {
            setFieldValue(name, event.target.checked);
          }
        }}
        {...otherProps}
      />

      <label className="form-check-label">
        {label}
        <div className="fw-bolder text-gray-800">{title}</div>
        <div className="text-gray-800">{description}</div>
      </label>
    </div>
  );
}

export default AppCheckInput;

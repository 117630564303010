import { useEffect } from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';

const usePreventionUnload = (value: ((evt: BeforeUnloadEvent) => any) | string | null) => {
  usePrompt({
    message: 'This page is asking you to confirm that you want to leave — information you’ve entered is not saved.',
    when: value !== null,
  });

  useEffect(() => {
    const handleBeforeunload = (evt: BeforeUnloadEvent) => {
      let returnValue;
      if (typeof value === 'function') {
        returnValue = value(evt);
      } else {
        returnValue = value;
      }
      if (returnValue) {
        evt.preventDefault();
        evt.returnValue = returnValue;
      }
      return returnValue;
    };
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => { window.removeEventListener('beforeunload', handleBeforeunload); };
  }, [value]);
};
export default usePreventionUnload;

// class for reusable behaviour in AG grid
import { type ColDef, type ProcessDataFromClipboardParams, type SuppressKeyboardEventParams } from 'ag-grid-enterprise';
import { type GridApi } from 'ag-grid-community/dist/lib/gridApi';
import NumericEditor from '../views/mapping/components/NumericEditor';
import { DIM_TABLE_ID, MappingConsts1, MappingConsts2 } from '../views/mapping/api/const';
import DatePickerEditor from './tables/DatePickerEditor';
import { CheckForValidDateAndReturnFormat } from './tables/DateFormatter';
import { type DimTableColumnInfoDto, type ImportFormFieldDto } from '../api/data-contracts';
import DropdownValueRenderer from '../views/mapping/components/DropdownValueRenderer';
import { changeDelimeterParser } from './tables/NumberFormatter';
import { Message } from './notifications/Message';

export const getDefaultContextMenu = (params: any, api: GridApi | any) => [
  'cut',
  'copy',
  'paste',
  {
    name: 'Delete row',
    action: () => {
      api.applyTransaction({ remove: [params.node.data], });
    },
  }
];

export const rowsDropdownValuesAreValid = (input: any, columns: DimTableColumnInfoDto[]) => {
  if (!input) {
    return true;
  }

  let isValid = true;

  input.forEach((test: any) => {
    Object.entries(test).forEach(([key, value]) => {
      const column = columns.find((col) => col.columnName === key);
      if (column?.dropdownValues) {
        const isValueValid = value === null ||
          value === undefined ||
          column.dropdownValues.some(v => typeof value === 'string' && typeof v === 'string' ? v.toLowerCase() === value.toLowerCase() : v === value);
        if (!isValueValid) {
          Message('Not allowed value in dropdown', `You tried to enter: ${value} which is not allowed in the column: ${key}`, 'error', {});
          isValid = false;
        }
      }
    });
  });

  return isValid;
};

export function processDataFromClipboard(
  params: ProcessDataFromClipboardParams
): string[][] | null {
  const data = [...params.data];

  // Remove an empty last row from clipboard data if exists.
  const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
  if (emptyLastRow) {
    data.splice(data.length - 1, 1);
  }

  const focusedCell = params.api.getFocusedCell();
  const focusedIndex = focusedCell!.rowIndex;
  const updates: any[] = [];
  const additions: any[] = [];

  for (let i = 0; i < data.length; i += 1) {
    const currentRowIndex = focusedIndex + i;
    const currentRowNode = params.api.getRowNode(currentRowIndex.toString());

    const row = data[i];
    const rowObject: any = {};
    let currentColumn: any = focusedCell!.column;

    row.forEach((item) => {
      if (!currentColumn) {
        return;
      }

      if (currentColumn.getColDef().editable) {
        const cellValue = item.trim();
        rowObject[currentColumn.getColDef().field] = cellValue;
      }

      currentColumn = params.columnApi.getDisplayedColAfter(currentColumn);
    });

    // If there is an existing row and it is empty, we update it.
    // Otherwise, if there is no existing row, we add a new one.
    if (currentRowNode) {
      const rowData = currentRowNode.data;
      const isRowEmpty = Object.values(rowData).every((cell) => !cell);

      if (isRowEmpty) {
        updates.push({ ...rowData, ...rowObject, });
      }
    } else {
      additions.push(rowObject);
    }
  }

  if (updates.length > 0) {
    params.api.applyTransaction({ update: updates, });
  }
  if (additions.length > 0) {
    params.api.applyTransaction({ add: additions, });

    setTimeout(() => { params.api.ensureIndexVisible(params.api.getModel().getRowCount() - 1, 'bottom'); }, 50);
  }

  return data;
}

export const navigateToNextEmptyCellAndEdit = async (
  e: any,
  gridRef: React.RefObject<any>,
  keyPressed: string
) => {
  const { column, } = e;
  const columnId = e.column.getColId();
  const nodeIndex = e.node.rowIndex!;

  let columnToMoveTo = -1;
  const rowData: any[] = [];

  gridRef.current!.api.forEachNodeAfterFilterAndSort(
    (node: any) => rowData.push(node.data[columnId])
  );
  gridRef.current!.api.clearRangeSelection();

  if (keyPressed === 'ArrowUp') {
    for (let i = nodeIndex - 1; i >= 0; i -= 1) {
      const cellValue = rowData[i];
      if (cellValue === undefined || cellValue === null || cellValue === '') {
        columnToMoveTo = i;
        break;
      }
    }
  } else {
    for (let i = nodeIndex + 1; i < rowData.length; i += 1) {
      const cellValue = rowData[i];
      if (cellValue === undefined || cellValue === null || cellValue === '') {
        columnToMoveTo = i;
        break;
      }
    }
  }

  // If the user holds the shift key, select the rows from the starting point
  if ((e.event as KeyboardEvent).shiftKey && columnToMoveTo === -1) {
    gridRef.current!.api.addCellRange({
      rowStartIndex: nodeIndex,
      rowEndIndex: keyPressed === 'ArrowUp' ? 0 : gridRef.current!.api.getDisplayedRowCount() - 1,
      columnStart: columnId,
      columnEnd: columnId,
    });
  }

  if (columnToMoveTo !== -1) {
    gridRef.current!.api.ensureIndexVisible(columnToMoveTo);
    gridRef.current!.api.setFocusedCell(columnToMoveTo, column);
    await new Promise((r) => setTimeout(r, 50));

    gridRef.current!.api.startEditingCell({
      rowIndex: columnToMoveTo,
      colKey: column,
    });
  } else {
    columnToMoveTo = keyPressed === 'ArrowUp' ? 0 : rowData.length - 1;
    gridRef.current!.api.ensureIndexVisible(columnToMoveTo);
    await new Promise((r) => setTimeout(r, 50));

    if (!(e.event as KeyboardEvent).shiftKey) {
      gridRef.current!.api.setFocusedCell(columnToMoveTo, column);
    }
  }
};

export function deleteRowsRangeSelection(gridApi: GridApi): any[] {
  if (!gridApi) {
    return [];
  }

  // Get all selected ranges using getCellRanges
  const selectedRanges = gridApi.getCellRanges();

  if (!selectedRanges || selectedRanges.length === 0) {
    return [];
  }

  // Extract all rows from the ranges
  const rowsToDelete: any[] = [];
  selectedRanges.forEach((range) => {
    // Assuming the startRow and endRow are always present in the range
    for (let i = range.startRow!.rowIndex; i <= range.endRow!.rowIndex; i += 1) {
      const rowNode = gridApi.getDisplayedRowAtIndex(i);
      rowsToDelete.push(rowNode!.data);
    }
  });

  gridApi.applyTransaction({ remove: rowsToDelete, });

  return rowsToDelete;
}

export function createAgColumnsArray(obj: DimTableColumnInfoDto[]) {
  const columns: ColDef[] = [];

  obj.forEach((element: DimTableColumnInfoDto) => {
    const colDef: ColDef = {
      headerName: element.columnName,
      field: element.columnName,
      hide: element.isHidden,
      sortable: true,
      // @ts-expect-error
      ...(element.columnDataType.includes('System.DateTime') && {
        cellEditor: DatePickerEditor,
        cellRenderer: (data: any) => CheckForValidDateAndReturnFormat(data.value, 'YYYY-MM-DD HH:mm:ss.SSS'),
      }),
      // @ts-expect-error
      ...(element.columnDataType.includes('System.Int32') && {
        cellEditor: NumericEditor,
        cellEditorParams: {
          allowDecimals: false,
        },
      }),
      ...(element.columnName === MappingConsts1.COLUMN_NAME_ACCOUNT_NR_NAME && {
        valueGetter: (params) => {
          const combined = `${params.data.account_nr}-${params.data.general_ledger_name}`;
          params.data.GL_account_nr_name = combined;
          return combined;
        },
      }),
      ...(element.columnName === MappingConsts1.COLUMN_NAME_GL_UNIQUE && {
        valueGetter: (params) => {
          const combined = `${params.data.account_nr}-${params.data.entity_name}`;
          params.data[MappingConsts1.COLUMN_NAME_GL_UNIQUE] = combined;
          return combined;
        },
      }),
      ...(element.columnName === MappingConsts2.COLUMN_NAME_GL_UNIQUE && {
        valueGetter: (params) => {
          const combined = `${params.data.account_nr}-${params.data.FK_entity_name}`;
          params.data[MappingConsts2.COLUMN_NAME_GL_UNIQUE] = combined;
          return combined;
        },
      }),
      ...(element.columnName === DIM_TABLE_ID && {
        hide: true,
      }),
      ...(element.isDisabled && {
        tooltipValueGetter: () => 'This row is not editable',
        editable: false,
        cellStyle: {
          backgroundColor: '#f4f4f5',
          color: '#6b7280',
          opacity: '0.7',
          fontWeight: 'lighter',
        },
      }),
      ...(element.dropdownValues && {
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: element.dropdownValues,
        },
        cellRenderer: DropdownValueRenderer,
      }),
    };
    columns.push(colDef);
  });

  return columns;
}

export function suppressKeys(params: SuppressKeyboardEventParams) {
  const KEY_DELETE = 'Delete';
  const KEY_ENTER = 'Enter';
  const KEY_ARROW_UP = 'ArrowUp';
  const KEY_ARROW_DOWN = 'ArrowDown';

  const { event, } = params;
  const { key, } = event;

  const keysToSuppress = [];

  if (event.ctrlKey || event.metaKey) {
    keysToSuppress.push(KEY_DELETE);
    keysToSuppress.push(KEY_ARROW_UP);
    keysToSuppress.push(KEY_ARROW_DOWN);
    keysToSuppress.push(KEY_ENTER);
  }

  return keysToSuppress.some(
    (suppressedKey) => suppressedKey === key || key.toUpperCase() === suppressedKey
  );
}

interface DropdownColumn {
  key: string;
  value: Array<string | number | null>;
  name: string;
}

export function getColumnsDefaultValues(fields: ImportFormFieldDto[]) {
  const dict: DropdownColumn[] = [];

  fields.forEach((columnIndex: any) => {
    if (columnIndex.type === 'dropdown') {
      dict.push({
        key: columnIndex.columnIdentifier,
        value: columnIndex.values,
        name: columnIndex.name,
      });
    }
  });

  return dict;
}

export function createAgColumnsFromCloneImportForms(fields: ImportFormFieldDto[]) {
  const columns: ColDef[] = [];

  // Add a row number column at the start
  columns.push({
    maxWidth: 80,
    minWidth: 70,
    filter: false,
    headerName: '',
    editable: false,
    suppressColumnsToolPanel: true,
    valueGetter: (params: any) => {
      if (!params.node) return true;

      const indexValue = params.node.rowIndex + 1;
      return indexValue.toString();
    },
    cellStyle: {
      textAlign: 'center',
      backgroundColor: '#a3a3a3',
      color: 'white',
      padding: 0,
    },
  });

  columns.push({
    headerName: 'Date Added',
    field: 'DateAdded',
    maxWidth: 120,
    minWidth: 70,
    filter: false,
    sortable: true,
    editable: false,
    cellRenderer: (params: any) => {
      if (!params.node) return true;

      if (!params.node.data.DateAdded) {
        const date = CheckForValidDateAndReturnFormat(new Date(), 'YYYY-MM-DD');
        params.node.data.DateAdded = date;

        return date;
      }
      return CheckForValidDateAndReturnFormat(params.node.data.DateAdded, 'YYYY-MM-DD');
    },
    cellStyle: {
      textAlign: 'center',
      backgroundColor: '#a3a3a3',
      color: 'white',
      padding: 0,
    },
  });

  fields.forEach((columnIndex: any) => {
    const commonProps: ColDef = {
      headerName: columnIndex.name,
      field: columnIndex.columnIdentifier,
      sortable: true,
    };

    let specificProps: ColDef = {};

    if (columnIndex.type === 'calendar') {
      specificProps = {
        singleClickEdit: true,
        sortable: true,
        cellEditor: DatePickerEditor,
        cellRenderer: (data: any) => CheckForValidDateAndReturnFormat(data.value, 'YYYY-MM-DD'),
      };
    } else if (columnIndex.type === 'numeric') {
      specificProps = {
        cellEditor: NumericEditor,
        type: 'numericColumn',
        valueSetter: (params) => {
          const { newValue, } = params;
          params.data[columnIndex.columnIdentifier] = changeDelimeterParser(
            newValue,
            columnIndex.datatype === 3
          );
          return true;
        },
        cellEditorParams: {
          allowDecimals: columnIndex.datatype === 3,
        },
      };
    } else if (columnIndex.type === 'dropdown') {
      const dropdownValues = columnIndex.datatype === 3 || columnIndex.datatype === 4
        ? columnIndex.values.sort((a: number, b: number) => a - b)
        : columnIndex.values.slice().sort(
          (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())
        );

      specificProps = {
        cellEditorPopup: true,
        singleClickEdit: true,
        cellEditor: 'agRichSelectCellEditor',
        type: columnIndex.datatype === 3 || columnIndex.datatype === 4 ? 'numericColumn' : undefined,
        cellEditorParams: {
          values: dropdownValues,
        },
        cellRenderer: DropdownValueRenderer,
      };
    }

    if (columnIndex.readonly) {
      specificProps.editable = false;
      specificProps.cellStyle = { backgroundColor: '#a3a3a3', color: '#6b7280', };
    }

    const colDef = { ...commonProps, ...specificProps, };

    if (colDef.headerName !== 'UniqueId') {
      columns.push(colDef);
    }
  });

  return columns;
}

export function getColumnsDefaultValuesImportForms(fields: ImportFormFieldDto[]) {
  const dict: DropdownColumn[] = [];

  fields.forEach((columnIndex: any) => {
    if (columnIndex.type === 'dropdown') {
      dict.push({
        key: columnIndex.columnIdentifier,
        value: columnIndex.values,
        name: columnIndex.name,
      });
    }
  });

  return dict;
}

import { param } from 'jquery';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import { BiHub_Host_Route, BiHub_Tenant_Route } from '../../../constants/routes';
import {
  type BiHubPipelineDto,
  type BiHubRunCurrentTenantPostDto,
  type BiHubStatusDto,
  type PipelineRunLogsDto,
  transformPipelineTaskResponse,
  type UpdatePipelineDto
} from './types';
import { type GetRequestFilter } from '../../../types';
import { Api } from '../../../api/ApiRoute';
import { replacePathParams } from '../../../utility/Utils';
import { FileContentResultResult, FilePreviewDto, FilePreviewDtoResult } from '../../../api/data-contracts';

const BiHubAPI = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getPipelineTenants: build.query({
      query() {
        return {
          url: `${BiHub_Host_Route}tenants`,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: string[]) => rawResult,
      providesTags: ['Tenant'],
    }),
    getPipeline: build.query({
      query(tenantId: number) {
        return {
          url: BiHub_Host_Route + tenantId,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: BiHubPipelineDto) => rawResult,
      providesTags: [],
    }),

    getPipelineForRunPipeline: build.query({
      query(input: Api.V1HostBiHubDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubDetail.BaseRequest.url, input),
          method: Api.V1HostBiHubDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubPipelineDto) => {
        const combinedTasks = rawResult.tasks;

        if (rawResult.powerBiDatasets !== null) {
          rawResult.powerBiDatasets.sort((a, b) => a.powerBiReportDisplayName.localeCompare(b.powerBiReportDisplayName))
            .forEach((dataSet) => {
              combinedTasks.push({
                id: dataSet.id,
                name: dataSet.powerBiReportDisplayName,
                type: 6,
                databaseName: '',
                sorting: null,
                action: '',
                runParallel: false,
                tenantCanRun: dataSet.isEnabledForTenant,
              });
            });
        }

        rawResult.tasks = combinedTasks;
        return rawResult;
      },
      providesTags: [],
    }),

    getPipelineTransformed: build.query({
      query(tenantId: number) {
        return {
          url: BiHub_Host_Route + tenantId,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: any): BiHubPipelineDto => transformPipelineTaskResponse(rawResult),
      providesTags: ['BiHubPipeline'],
    }),

    getPipelineLogs: build.query({
      query(arg: { input: GetRequestFilter; tenantId: number | null }) {
        return {
          url: `${BiHub_Host_Route}Logs${arg.tenantId ? `?TenantId=${arg.tenantId}` : ''}`,
          method: 'GET',
          params: arg.input,
        };
      },
      transformResponse: (rawResult: { data: { totalCount: number; items: PipelineRunLogsDto[] } }) => {
        rawResult.data.items.forEach((item) => {
          if (item.biHubPowerBiDatasetRefreshes !== null) {
            const enrichedBiHubPowerBiDatasetRefreshes = item.biHubPowerBiDatasetRefreshes.map((refresh) => ({
              ...refresh,
              action: 'Power BI dataset refresh',
              name: refresh.reportName,
              type: 6,
            }));

            item.biHubTaskRuns = item.biHubTaskRuns.concat(enrichedBiHubPowerBiDatasetRefreshes);
          }
        });

        return rawResult.data;
      },
      providesTags: ['BiHubPipelineLogs'],
    }),

    getBiHubStatus: build.query({
      query() {
        return {
          url: `${BiHub_Tenant_Route}status`,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: { data: BiHubStatusDto }) => rawResult.data,
      providesTags: ['BiHubTenantStatus'],
    }),

    runPipelineForCurrentTenant: build.mutation({
      query(payload: BiHubRunCurrentTenantPostDto) {
        return {
          url: `${BiHub_Tenant_Route}Run`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['BiHubTenantStatus'],
    }),

    updatePipeline: build.mutation({
      query(arg: { payload: UpdatePipelineDto }) {
        return {
          url: BiHub_Host_Route,
          method: 'PUT',
          body: arg.payload,
        };
      },
      invalidatesTags: ['BiHubPipeline'],
    }),

    runPipeline: build.mutation({
      query(arg: { payload: { taskIds: number[]; tenantId: number; PowerBiDatasetIds: number[] } }) {
        return {
          url: `${BiHub_Host_Route}Run`,
          method: 'POST',
          body: arg.payload,
        };
      },
      invalidatesTags: ['BiHubPipelineLogs'],
    }),

    getLogsPreview: build.query({
      query: (input: Api.V1HostBiHubLogsPreviewLogDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostBiHubLogsPreviewLogDetail.BaseRequest.url, input),
        method: Api.V1HostBiHubLogsPreviewLogDetail.BaseRequest.method,
      }),
      transformResponse: (data: FileContentResultResult) => data,
    }),
  }),
});

export const {
  useLazyGetPipelineLogsQuery,
  useGetPipelineTenantsQuery,
  useGetBiHubStatusQuery,
  useLazyGetPipelineQuery,
  useLazyGetPipelineForRunPipelineQuery,
  useUpdatePipelineMutation,
  useRunPipelineMutation,
  useGetPipelineTransformedQuery,
  useRunPipelineForCurrentTenantMutation,
  useGetLogsPreviewQuery
} = BiHubAPI;

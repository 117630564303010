// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {

    layout: 'auth',
    lastLayout: 'auth',
  },
  reducers: {
    handleLayout: (state, action) => {
      state.layout = action.payload;
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload;
    },
  },
});

export const {
  handleLayout,
  handleLastLayout,
} = layoutSlice.actions;

export default layoutSlice.reducer;

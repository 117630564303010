import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useJwt from '../../../auth/jwt/useJwt';
import { type LoginOutput, type MasterUserTenant } from '../../../types';
import TenantCard from '../cards/TenantCard';
import { useNavigate } from 'react-router';
import routeNames from '../../../routing/routeNames';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import { Message } from '../../../utility/notifications/Message';

interface ISelectTenantSliderProps {
  availableTenants: MasterUserTenant[];
  onClick: (value: number) => void;
  showHostEnvLink: boolean;
  handleClose: () => void;
}

const SelectTenantSlider: React.FunctionComponent<ISelectTenantSliderProps> = ({
  availableTenants,
  onClick,
  showHostEnvLink,
  handleClose,
}: ISelectTenantSliderProps) => {
  const jwt = useJwt().jwt;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const trueFirstTenants = [...availableTenants].sort(
    (a, b) => Number(b.isFavorite) - Number(a.isFavorite)
  );

  const selectTenant = (id: number) => {
    setIsLoading(true);
    jwt.login(id)
      .then((res) => {
        if (res.status === 200) {
          const permissions = res.data.data.userData.grantedPermissions;
          jwt.setPermissions(permissions);

          delete res.data.data.userData.grantedPermissions;
          const data: LoginOutput = {
            accessToken: res.data.data.accessToken,
            encryptedAccessToken: res.data.data.encryptedAccessToken,
            userData: {
              ...res.data.data.userData,
              hasAccessToHostEnvironment: showHostEnvLink,
              tenants: availableTenants.map((t) => t.id),
            },
          };

          jwt.handleLogin(data);
          dispatch(emptySplitApi.util.resetApiState());
          navigate(routeNames.fallback);
          handleClose();
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response) {
          Message('Log in failed!', err.response.data.exception, 'error', {});
        }
      });
  };

  return (
    <div className='d-flex flex-md-row flex-column flex-nowrap overflow-auto' id='custom-scrollbar'>
      {trueFirstTenants.map((tenant) => (
        <TenantCard
          key={tenant.id}
          name={tenant.name}
          image={tenant.tenantLogo}
          favourite={tenant.isFavorite}
          tenantId={tenant.id}
          onClick={() => { onClick(tenant.id); }}
          onCardClick={() => {
            selectTenant(tenant.id);
          }}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};

export default SelectTenantSlider;

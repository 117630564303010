/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from 'react';
import { useLayout } from '../core';
import { toAbsoluteUrl } from '../../helpers';

const Footer: FC = () => {
  const { classes, } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-row flex-lg-column" id="kt_footer">
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="flex-d flex-row text-dark">

          <div className="text-gray-800 text-hover-primary">
            <a href="#" className="text-gray-800 text-hover-primary">
              Business Insight © {new Date().getFullYear()}
            </a>
          </div>
        </div>

        <div className="text-primary">
          Powered by

          <img
            alt="Sincerius logo"
            className="ms-3"
            src={toAbsoluteUrl('/media/logos/Logo-Sincerius-large-dark.png')}
            width="130px"
          />
        </div>
      </div>
    </div>
  );
};

export { Footer };

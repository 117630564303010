import React, {
  type ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { type ColDef, type IFilterOptionDef, ModuleRegistry } from 'ag-grid-enterprise';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { type CellKeyDownEvent, type GetRowIdFunc, type GetRowIdParams } from 'ag-grid-community';
import { navigateToNextEmptyCellAndEdit, suppressKeys } from '../../../utility/AgUtils';
import './styles/ag-grid.css';

export interface Props {
  deleteRow: any;
  insertRow: any;
  updateRow: any;
  getContextMenuItems: any;
  processDataFromClipboard: any;
  isLoading: boolean;
  data: Array<Record<string, any>>;
  rowIdParam: string;
  columns: ColDef[];
  toolbar?: ReactNode;
  undoDelete?: any;
  editable?: boolean;
  height?: string;
}

export interface AppGridHandler {
  addNewRow: () => void;
  undo: () => void;
  saveGrid: () => Promise<any[]>;
}

ModuleRegistry.registerModules([
  RangeSelectionModule,
  ClipboardModule
]);

const AppGridCrud = forwardRef<AppGridHandler, Props>(({
  data,
  deleteRow,
  insertRow,
  updateRow,
  getContextMenuItems,
  processDataFromClipboard,
  isLoading,
  columns,
  rowIdParam,
  toolbar,
  undoDelete,
  height = '100%',
  editable = true,
}, ref): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height, }), [height]);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%', }), []);

  useImperativeHandle(ref, () => ({
    addNewRow() {
      insertRow(-1, gridRef);
    },

    undo() {
      undoDelete(gridRef);
    },

    async saveGrid() {
      return await handleSaveChanges();
    },
  }), [insertRow, undoDelete]);

  const handleSaveChanges = async () => {
    gridRef.current!.api.stopEditing();

    const rowData: any[] = [];
    gridRef.current!.api.forEachNode((node) => {
      rowData.push(node.data);
    });
    return rowData;
  };

  const handleCellValueChanged = useCallback((params: any) => {
    updateRow(params.data);
  }, [updateRow]);

  const getRowId = useMemo<GetRowIdFunc>(() => (params: GetRowIdParams) => `${params.data[rowIdParam]}`, []);

  const containsFilterParams = React.useMemo(() => {
    return {
      excelMode: 'windows',
      filterOptions: [
        {
          displayKey: 'contains',
          displayName: 'Contains',
          predicate: ([fv1]: any[], cellValue) => {
            return cellValue == null || new RegExp(fv1, 'gi').test(cellValue);
          },
          numberOfInputs: 1,
        },
        {
          displayKey: 'equals',
          displayName: 'Equals',
          predicate: ([fv1]: any[], cellValue) => {
            return cellValue === fv1;
          },
          numberOfInputs: 1,
        }
      ] as IFilterOptionDef[],
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => ({
    resizable: true,
    floatingFilter: true,
    editable,
    suppressKeyboardEvent: (params) => suppressKeys(params),
    filter: 'agTextColumnFilter',
    filterParams: containsFilterParams,
    menuTabs: ['filterMenuTab'],
  }), [containsFilterParams, editable]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const { api, } = gridRef.current;
      if (isLoading) {
        api.showLoadingOverlay();
      } else {
        api.hideOverlay();
      }
    }
  }, [isLoading]);

  const onFirstDataRendered = () => {
    const columnCount = gridRef.current!.api.getColumnDefs()!.length;
    const columnThreshold = 8;

    if (columnCount <= columnThreshold) {
      gridRef.current!.api.sizeColumnsToFit();
    }
  };

  const onCellKeyDown = useCallback(async (e: CellKeyDownEvent) => {
    if (e.event && gridRef.current) {
      const keyPressed = (e.event as KeyboardEvent).key;
      if (keyPressed === 'Delete' && (e.event as KeyboardEvent).ctrlKey) {
        deleteRow(gridRef);
      }

      if (keyPressed === 'Enter' && (e.event as KeyboardEvent).ctrlKey) {
        insertRow(e.node.rowIndex! + 1, gridRef);
      }

      if ((e.event as KeyboardEvent).ctrlKey && (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown')) {
        navigateToNextEmptyCellAndEdit(e, gridRef, keyPressed);
      }
    }
  }, [deleteRow, insertRow]);

  return (
    <>
      {toolbar}

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columns}
            defaultColDef={defaultColDef}

            scrollbarWidth={10}
            undoRedoCellEditingLimit={5}
            rowHeight={25}

            // We refresh the cells so the index number will also update
            onSortChanged={() => gridRef && gridRef.current?.api.refreshCells()}
            onFilterChanged={() => gridRef && gridRef.current?.api.refreshCells()}
            onFirstDataRendered={onFirstDataRendered}
            onCellKeyDown={onCellKeyDown}
            onCellValueChanged={handleCellValueChanged}

            getRowId={getRowId}
            getContextMenuItems={(params) => getContextMenuItems(params, gridRef)}
            processDataFromClipboard={processDataFromClipboard}

            enableBrowserTooltips
            enableRangeSelection
            undoRedoCellEditing
            suppressModelUpdateAfterUpdateTransaction
            deltaSort

            overlayLoadingTemplate={
              '<div style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>'
            }
          />
        </div>
      </div>
    </>
  );
});

export default React.memo(AppGridCrud);

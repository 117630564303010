import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import clsx from 'clsx';
import * as React from 'react';
import { type ReactElement } from 'react';

interface Props {
    text: string;
    popoverElement: ReactElement;
}

function AppToolTip ({ popoverElement, text, }: Props) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={popoverElement}
    >
      <button type="button" className="btn btn-secondary btn-sm btn-flex">
        <i className={clsx('fs-4 fa fa-info-circle')} />
        {text}
      </button>
    </OverlayTrigger>
  );
}

export default AppToolTip;

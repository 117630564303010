import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Accordion } from 'react-bootstrap';
import { FieldArray, type FieldArrayRenderProps, type FormikValues } from 'formik';
import AppTextInput from '../../components/forms/AppTextInput';
import { type PowerBiReportParameterDto } from '../PowerBi/api/types';
import AppFormDropdown from '../../components/forms/AppFormDropdown';
import { convertDataTypeParameterToHtmlInput } from '../../../utility/Utils';

interface Props {
  id: number;
  powerBiFormField: PowerBiReportParameterDto;
  index: number;
  renderProp: FieldArrayRenderProps;
  name: string;
  nestedName: string;
  values: FormikValues;
  dataType: Map<number, string>;
}

export function SortableParameterItem({
  id,
  powerBiFormField,
  index,
  renderProp,
  name,
  nestedName,
  values,
  dataType,
}: Props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handlePaste = (arrayHelpersParamValues: FieldArrayRenderProps, pastedData: string) => {
    const parsedData = pastedData.split(/\r\n|\n|\r/).filter((line: string) => line.trim() !== '');

    parsedData.forEach(value => {
      arrayHelpersParamValues.push({ value });
    });
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h4>{powerBiFormField.name}</h4>
            <i className=" btn bi bi-grip-vertical fs-2" {...listeners} {...attributes} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row align-items-end">
            <div className="col-12 mb-5">
              <AppTextInput
                type="text"
                name={`${name}[${index}].name`}
                placeholder="Parameter name"
                label="Parameter name"
                required={false}
                appendIcon="fa-solid fa-trash"
                onClick={() => renderProp.remove(index)}
              />
            </div>

            <div className="col-12">
              <AppFormDropdown
                name={`${name}[${index}].dataType`}
                optionsMap={dataType}
                label="Select data type"
                required
              />
            </div>

            <FieldArray
              name={`${name}.${index}.${nestedName}`}
              render={(arrayHelpersParamValues) => (
                <>
                  {values.parameters[index].values && values.parameters[index].values.length > 0 && (
                    values.parameters[index].values.map((field: any, valuesIndex: number) => (
                      <div key={valuesIndex}>
                        <div className="row align-items-end">
                          <div className="fv-row mt-5">
                            <AppTextInput
                              type={convertDataTypeParameterToHtmlInput(values.parameters[index].dataType)}
                              name={`${name}.${index}.${nestedName}.${valuesIndex}.value`}
                              label=""
                              placeholder="Parameter value"
                              required={false}
                              appendIcon="fa-solid fa-trash"
                              onClick={() => arrayHelpersParamValues.remove(valuesIndex)}
                              onPaste={(e) => {
                                handlePaste(arrayHelpersParamValues, e);
                              }} />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  <div
                    onClick={() => { arrayHelpersParamValues.push({ value: '', }); }}
                    className="w-100 mt-2 flex-d align-items-center-content-center btn btn-icon btn-active-light btn-active-color-primary "
                  >
                    <span className="svg-icon svg-icon-1">
                      <i className="bi bi-plus fs-2x " />
                      Add value
                    </span>
                  </div>
                </>
              )}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

export interface ImportForm {
    name?: string;
    tenantId?: number | null;
    description?: string;
    storedProcedure?: string;
    fields?: ImportFormField[];
}

export const DataTypesImportFormMap = new Map<number, string>([
  [1, 'Text'],
  [2, 'Calendar'],
  [3, 'Numeric'],
  [4, 'Integer']
]);

export const columnIdentifierArray = [
  'String1',
  'String2',
  'String3',
  'String4',
  'String5',
  'String6',
  'String7',
  'String8',
  'String9',
  'String10',
  'Integer1',
  'Integer2',
  'Integer3',
  'Integer4',
  'Integer5',
  'Integer6',
  'Integer7',
  'Integer8',
  'Integer9',
  'Integer10',
  'Date1',
  'Date2',
  'Date3',
  'Date4',
  'Date5',
  'Numeric1',
  'Numeric2',
  'Numeric3',
  'Numeric4',
  'Numeric5',
  'Numeric6',
  'Numeric7',
  'Numeric8',
  'Numeric9',
  'Numeric10',
  'Numeric11',
  'Numeric12',
  'Numeric13',
  'Numeric14',
  'Numeric15',
  'Numeric16',
  'Numeric17',
  'Numeric18',
  'Numeric19',
  'Numeric20'
];

export interface ImportFormDto {
    name?: string;
    viewGroupId?: number;
    tenantId?: number | null;
    description?: string;
    storedProcedure?: string;
    fields?: ImportFormFieldDto[];
}

export interface ImportFormFieldDto {
    id?: number;
    name?: string;
    datatype?: number;
    hasDefaultValues?: boolean;
    sorting?: number;
    columnIdentifier?: string;
    values?: ImportDefaultValue[];
}

export interface ImportFormField {
    id?: number;
    sortId?: number;
    name: string;
    columnIdentifier: string;
    hasDefaultValues: boolean;
    sorting: number;
    defaultValues?: ImportDefaultValue[];
}

export interface ImportDefaultValue {
    id?: number;
    value: string;
}

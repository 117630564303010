/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, type ReactNode } from 'react';

import IsRouteOrActionAllowed from '../../utility/IsRouteOrActionAllowed';
import { Error401 } from '../errors/components/Error401';

interface Props {
    children: ReactNode;
    meta?: ReactNode;
    title: string;
    permission?: string;
    utilityPage?: boolean;
}

const Page = forwardRef<HTMLDivElement, Props>(
  ({
    children, title = '', meta, permission, utilityPage, ...other
  }, ref) => {
    const isAllowed = !utilityPage
      ? IsRouteOrActionAllowed.IsRouteOrActionAllowed(permission as any) !== undefined
        ? IsRouteOrActionAllowed.IsRouteOrActionAllowed(permission as any)
        : false
      : false;

    if (utilityPage) {
      return (
        <div ref={ref} {...other} className="mt-6">
          {children}
        </div>
      );
    }

    return (
      <div ref={ref} {...other} className="h-100">
        {isAllowed ? children : <Error401 />}
      </div>
    );
  }
);

export default Page;

/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import {
  Common_Roles_View,
  Common_Users_View,
  Tenant_Dashboards,
  Tenant_Documents_View,
  Tenant_ImportForms_View,
  Tenant_ManagementReports_View,
  Tenant_Mapping_View
} from '../../../../app/constants/permissions';
import routeNames from '../../../../app/routing/routeNames';

export function AsideMenuTenant () {
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pb-2">
          <span className="text-white text-uppercase fs-8 ls-1">Company</span>
        </div>
      </div>

      <AsideMenuItem
        to={routeNames.ssrs}
        icon="/media/icons/duotune/art/art002.svg"
        title="Management Reports"
        fontIcon="las la-newspaper"
        permission={Tenant_ManagementReports_View}
      />

      <AsideMenuItem
        to={routeNames.powerBi}
        icon="/media/icons/duotune/art/art002.svg"
        title="Dashboards"
        fontIcon="las fa-bar-chart"
        permission={Tenant_Dashboards}
      />

      <AsideMenuItem
        to={routeNames.documentCabinet}
        icon="/media/icons/duotune/art/art002.svg"
        title="Documents"
        fontIcon="las la-folder"
        permission={Tenant_Documents_View}
      />

      <AsideMenuItem
        to={routeNames.importForm}
        icon="/media/icons/duotune/art/art002.svg"
        title="Import Forms"
        fontIcon="las la-table"
        permission={Tenant_ImportForms_View}
      />

      <AsideMenuItemWithSub
        to="/crafted/accounts"
        title="Administration"
        icon="/media/icons/duotune/communication/com006.svg"
        fontIcon="las la-sliders-h"
        permissions={[Common_Roles_View, Common_Users_View]}
      >
        <AsideMenuItem
          to={routeNames.role}
          title="Roles"
          fontIcon="las la-user-tag"
          permission={Common_Roles_View}
        />
        <AsideMenuItem
          to={routeNames.user}
          title="Users"
          fontIcon="las la-users-cog"
          permission={Common_Users_View}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to={routeNames.tenantMapping}
        icon="/media/icons/duotune/art/art002.svg"
        title="Account Mapping"
        fontIcon="las la-sitemap"
        permission={Tenant_Mapping_View}
      />
    </>
  );
}

import * as React from 'react';
import clsx from 'clsx';

export interface IAppFormLabelProps {
    label?: string;
    required: boolean;
}

export default function AppFormLabel ({ label, required, }: IAppFormLabelProps) {
  if (!label) {
    return null;
  }
  return (
    <label className={clsx('form-label fs-6 text-gray-400\'', { required, })}>{label}</label>
  );
}

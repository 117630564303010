import clsx from 'clsx';
import styles from './Form.module.scss';

export interface IAppFormInputButtonProps {
    toggleIcon: string;
    onToggleClick?: () => void;
}

export default function AppFormInputButton (props: IAppFormInputButtonProps) {
  return (
    <div className="input-group-append">
      <button
        onClick={props.onToggleClick}
        className={clsx('btn btn-secondary AppFormButton', styles.AppFormButton)}
        type="button"
      >
        <i
          className={props.toggleIcon}
        />
      </button>
    </div>
  );
}

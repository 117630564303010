export const changeDelimeterParser = (value: string | number, allowDecimals: boolean): number | undefined => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  if (!allowDecimals) {
    return parseInt(value.toString());
  }

  const changedDelimeter = value.toString().replace(/\,/g, '.');

  return parseFloat(changedDelimeter);
};

import {
  createContext, type FC, useContext, useState
} from 'react';
import { type WithChildren } from '../../../_metronic/helpers';
import useModal from './useModal';
import {
  type PaginationState, type QueryRequestContextProps, type SearchState, type SortState
} from '../../types';

export const initialQueryState: QueryState = {
  skipCount: 0,
  maxResultCount: 10,
  page: 1,
  totalItemCount: 0,
  pageCount: 0,
};

export type QueryState = PaginationState & SortState & SearchState;

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

const ListViewContext = createContext<IListViewContext>({
  isShowing: false,
  toggle (): void {
  },
  id: null,
  impersonateToggle (): void {
  },
  impersonateIsShowing: false,
  impersonateId: null,
  state: initialQueryRequest.state,
  updateState (): void {},
});

interface IListViewContext {
    isShowing: boolean;
    toggle: (id: number | null) => void;
    id: number | null;
    impersonateToggle: (id: number | null) => void;
    impersonateIsShowing: boolean;
    impersonateId: number | null;
    state: QueryState;
    updateState: (updates: Partial<QueryState>) => void;
}

const TenantListViewProvider: FC<WithChildren> = ({ children, }) => {
  const { toggle, isShowing, id, } = useModal();
  const { toggle: impersonateToggle, isShowing: impersonateIsShowing, id: impersonateId, } = useModal();

  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates, } as QueryState;
    setState(updatedState);
  };

  return (
    <ListViewContext.Provider value={{
      toggle, isShowing, id, impersonateToggle, impersonateIsShowing, impersonateId, state, updateState,
    }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { TenantListViewProvider, useListView };

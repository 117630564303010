/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

interface Props {
    className: string;
    color: string;
    bodyComp?: React.ReactNode;
    title: string;
    buttonComponent?: React.ReactNode;
    heigth?: string;
}

const MixedWidget: React.FC<Props> = ({
  className, color, bodyComp, title, buttonComponent, heigth,
}) => (
  <div className={`card ${className}`}>
    <div className="card-body p-0">
      <div className={`px-9 pt-7 card-rounded ${heigth ?? 'h-200px'}  w-100 bg-${color}`}>
        <div className="d-flex flex-stack">
          <h3 className="m-0 text-white fw-bold fs-3">{title}</h3>
          <div className="ms-1">
            {buttonComponent}
          </div>
        </div>
      </div>

      {bodyComp}
    </div>
  </div>
);

export { MixedWidget };

export const User_Host_Route = 'host/users/';
export const MasterUser_Host_Route = 'host/masterusers/';
export const User_Common_Route = 'common/users/';
export const Role_Common_Route = 'common/roles/';
export const PowerBi_Tenant_Route = 'tenant/powerbi/';
export const PowerBiApi_Tenant_Route = 'tenant/PowerBiApi/';
export const PowerBiApi_PaginatedReport_Tenant_Route = 'tenant/PowerBiApi/';
export const PowerBiApi_Host_Route = 'host/PowerBiApi/';
export const PowerBiApi_PaginatedReport_Host_Route = 'host/PowerBiApi/paginatedReport/';
export const Import_Form_Logs_Host_Route = 'host/Logs/ImportFormSqlLogs/';
export const Document_Cabinet_Logs_Host_Route = 'host/Logs/DocumentCabinetLogs/';

export const Tenant_Host_Route = 'host/tenants/';
export const Tenant_Common_Route = 'common/tenants/';

export const ViewGroups_Host_Route = 'host/ViewGroups/';
export const ViewGroups_Common_Route = 'common/ViewGroups/';
export const ViewGroups_Tenant_Route = 'tenant/ViewGroups/';

export const Ssrs_Host_Route = 'host/Ssrs/';

export const UserProfile_Common_Route = 'UserProfile/';

export const ImportForms_Host_Route = 'host/ImportForms/';
export const BiHub_Host_Route = 'host/BIHub/';
export const BiHub_Tenant_Route = 'tenant/BIHub/';
export const ImportForms_Tenant_Route = 'tenant/ImportForms/';

export const PowerBI_Host_Route = 'host/PowerBI/';
export const PaginatedReport_Host_Route = 'host/PowerBI/PaginatedReport/';
export const PaginatedReport_Editable_Host_Route = 'host/PowerBI/';
export const PowerBI_Common_Route = 'common/PowerBI/';
export const PowerBI_Tenant_Route = 'tenant/PowerBi/';

export const DocumentCabinet_Tenant_Route = 'tenant/DocumentCabinet/';

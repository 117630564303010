import { Api } from '../../../api/ApiRoute';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import { MAPPING_HOST_API_URL } from './const';
import { type DimTableDto, type MappingErrorDto } from './types';

const logsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    GetAvailableDimTables: build.query({
      query: (tenantId) => ({
        url: `${MAPPING_HOST_API_URL}/GetAvailableDimTables/${tenantId}`,
        method: 'GET',
      }),
      transformResponse: (rawResult: { messages: string[] }) => rawResult.messages.map((str) => ({ value: str, label: str, })),
    }),

    ExecuteMappingStoredProcedure: build.mutation({
      query: (tenantId) => ({
        url: `${MAPPING_HOST_API_URL}/ExecuteMappingStoredProcedure/${tenantId}`,
        method: 'GET',
      }),
      invalidatesTags: ['MissingMappings'],
    }),

    GetDimMapping: build.query({
      query: (arg) => ({
        url: `${MAPPING_HOST_API_URL}?TenantId=${arg.tenantId}&DimMappingTable=${arg.dimValue}`,
        method: 'GET',
      }),
      transformResponse: (rawResult: { data: DimTableDto }) => rawResult.data,
      providesTags: ['Mapping'],
    }),

    GetMissingMappings: build.query({
      query: () => ({
        url: `${MAPPING_HOST_API_URL}/GetMissingMappings`,
        method: 'GET',
      }),
      transformResponse: (rawResult: { data: MappingErrorDto[] }) => rawResult.data,
      providesTags: ['MissingMappings'],
    }),

    UpdateDimMapping: build.mutation({
      query: (input: Api.V1HostDimMappingUpdate.RequestBody) => ({
        url: Api.V1HostDimMappingUpdate.BaseRequest.url,
        method: Api.V1HostDimMappingUpdate.BaseRequest.method,
        body: input,
      }),
      invalidatesTags: (result) => {
        if (!result) return [];

        return result.shouldInvalidate
          ? [{ type: 'Mapping', }]
: [];
      },
    }),

    GetDimMappingTenant: build.query({
      query: () => ({
        url: Api.V1TenantDimMappingList.BaseRequest.url,
        method: Api.V1TenantDimMappingList.BaseRequest.method,
      }),
      transformResponse: (rawResult: { data: DimTableDto }) => rawResult.data,
      providesTags: ['Mapping'],
    }),

    ExecuteMappingStoredProcedureTenant: build.mutation({
      query: () => ({
        url: Api.V1TenantDimMappingExecuteMappingStoredProcedureList.BaseRequest.url,
        method: Api.V1TenantDimMappingExecuteMappingStoredProcedureList.BaseRequest.method,
      }),
      invalidatesTags: ['MissingMappings'],
    }),

    GetMissingMappingsTenant: build.query({
      query: () => ({
        url: Api.V1TenantDimMappingGetMissingMappingsList.BaseRequest.url,
        method: Api.V1TenantDimMappingGetMissingMappingsList.BaseRequest.method,
      }),
      transformResponse: (rawResult: { data: MappingErrorDto[] }) => rawResult.data,
      providesTags: ['MissingMappings'],
    }),

    UpdateDimMappingTenant: build.mutation({
      query: (input: Api.V1TenantDimMappingUpdate.RequestBody) => ({
        url: Api.V1TenantDimMappingUpdate.BaseRequest.url,
        method: Api.V1TenantDimMappingUpdate.BaseRequest.method,
        body: input,
      }),
      invalidatesTags: (result) => {
        if (!result) return [];

        return result.shouldInvalidate
          ? [{ type: 'Mapping', }]
: [];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetDimMappingQuery,
  useGetDimMappingQuery,
  useUpdateDimMappingMutation,
  useUpdateDimMappingTenantMutation,
  useGetMissingMappingsQuery,
  useGetAvailableDimTablesQuery,
  useExecuteMappingStoredProcedureMutation,
  useGetDimMappingTenantQuery,
  useGetMissingMappingsTenantQuery,
  useExecuteMappingStoredProcedureTenantMutation,
} = logsApi;

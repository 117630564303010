import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import AppFormError from './AppFormError';
import { objectByString } from '../../../utility/Utils';
import { Message } from '../../../utility/notifications/Message';

interface AppImageUploadProps {
  name: string;
  label: string;
  handleImageChange: (file: File) => void;
  imagePreview: string;
  sizeInBytes: number;
  required: boolean;
}

function AppImageUpload ({
  label,
  name,
  handleImageChange,
  imagePreview,
  sizeInBytes,
  required,
}: AppImageUploadProps) {
  const {
    errors, setFieldValue, setFieldTouched, touched,
  } = useFormikContext();

  useEffect(() => {
    if (imagePreview) {
      setFieldValue(name, 'previewSet');
    }
  }, [imagePreview, name, setFieldValue]);

  const fileInputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;
    const chosenFile = fileList[0];

    if (chosenFile.size > sizeInBytes) {
      Message(
        'File size to large',
        `${chosenFile.name} size is to large. Maximum is 200kb`,
        'error',
        ''
      );
      return;
    }

    setFieldTouched(name);

    handleImageChange(chosenFile);
  };

  return (
    <div className="fv-row mb-5">
      <label className={`d-block fw-bold fs-6 mb-5${required && 'required'}`}>{label}</label>
      <label
        className="btn btn-outline btn-outline-dashed btn-outline-default p-5"
        data-kt-image-input-action="change"
        data-bs-toggle="tooltip"
        title="Change avatar"
      >
        {imagePreview
? (
  <div className="image-input image-input-outline" data-kt-image-input="true">
    <div className="p-0 mx-4 w-10 d-flex flex-column align-content-center justify-content-center">
      <img
        className="img-fluid"
        style={{ width: '120px', }}
        src={imagePreview}
        alt="Company logo"
              />
    </div>
    <label
      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
      data-kt-image-input-action="change"
      data-bs-toggle="tooltip"
      title="Change avatar"
            >
      <i className="bi bi-pencil-fill fs-7" />
      <input
        type="file"
        id="img"
        name="img"
        accept=".png, .jpg, .jpeg .jfif"
        onChange={(e) => { fileInputHandleChange(e); }}
              />
    </label>
  </div>
        )
: (
  <div className="">
    <input
      className="position-absolute opacity-0 "
      style={{ zIndex: -1, }}
      type="file"
      id="img"
      name="img"
      accept=".png, .jpg, .jpeg .jfif"
      onChange={(e) => { fileInputHandleChange(e); }}
            />
    <div className="image-input image-input-outline" data-kt-image-input="true">
      <span className="svg-icon svg-icon-3x">
        <i className="bi bi-file-earmark-arrow-up text-primary fs-3x" />
      </span>
      <span className="d-block fw-bold text-start">
        <span className="text-dark fw-bolder d-block fs-4 ">Select a logo</span>
      </span>
    </div>
  </div>
        )}
      </label>
      <div className="form-text">Allowed file types: png, jpg, jpeg jfif and max 200kb</div>
      <AppFormError
        visible={objectByString(touched, name)}
        error={objectByString(errors, name)}
      />
    </div>
  );
}

export default AppImageUpload;

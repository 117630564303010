/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetTenantByIdQuery, useGetTenantLogoQuery } from '../tenant/api/api';
import { ImpersonateModalWrapper } from '../tenant/modal/impersonate/ImpersonateModalWrapper';
import { useListView } from '../tenant/TenantListView';
import routeNames from '../../routing/routeNames';
import { EditModalWrapper } from '../tenant/modal/edit/EditModalWrapper';

const ManageContentHeader: React.FC = () => {
  const location = useLocation();
  const params = useParams();

  const { impersonateToggle, impersonateIsShowing, } = useListView();

  const { toggle, isShowing, } = useListView();

  const { data, isLoading, } = useGetTenantByIdQuery(params.id);

  const { data: logo, } = useGetTenantLogoQuery(params.id);

  if (!data) return null;

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="p-0 mx-4 w-10 d-flex flex-column align-content-center justify-content-center">
            <img
              className="img-fluid"
              style={{ width: '150px', }}
              src={`data:image/jpg;base64, ${logo}`}
              alt="Company logo"
            />
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <h1><b>{data.name}</b></h1>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <h3>
                    Project name:
                    <b>{data.projectName}</b>
                  </h3>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <span className="badge badge-secondary">{data.editionId === 1 ? 'BI' : 'FDD'}</span>
                </div>

              </div>
            </div>
          </div>

          <div className="d-flex my-4 h-100">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() => { toggle(parseInt(params.id!)); }}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => { impersonateToggle(parseInt(params.id!)); }}
            >
              Impersonate user in company
            </button>

          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ${location.pathname.includes('/overview') && 'active'}`
                }
                to={`${routeNames.tenant}/${params.id}/content/overview`}
              >
                SSRS reports
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ${location.pathname.includes('/importForm') && 'active'}`
                }
                to={`${routeNames.tenant}/${params.id}/content/importForms`}
              >
                Import forms
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ${location.pathname.includes('/powerbi') && 'active'}`
                }
                to={`${routeNames.tenant}/${params.id}/content/powerbi`}
              >
                Power BI
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ${location.pathname.includes(routeNames.contentPaginatedReport) && 'active'}`
                }
                to={`${routeNames.tenant}/${params.id}${routeNames.contentPaginatedReport}`}
              >
                Paginated report
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {isShowing && <EditModalWrapper />}
      {impersonateIsShowing && <ImpersonateModalWrapper />}
    </div>
  );
};

export { ManageContentHeader };

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  type ImpersonateData, type ImpersonateOutput, type JwtConfig, type LoginOutput, type MasterLoginOutput, type UserData
} from '../types';
// ** Redux Imports

// ** UseJWT import to get config
import useJwt from '../auth/jwt/useJwt';
import { type CurrentUserPermissionsDto } from './types';
import { refreshBrowserTabs } from '../utility/Utils';

// https://redux.js.org/usage/usage-with-typescript
interface AuthenticationState {
    refreshToken?: string | null;
    accessToken?: string | null;
    accessMasterToken?: string | null;
    twoFactorRememberToken?: string | null;
    userData: UserData | {};
    impersonateData: ImpersonateData | {};
    config: JwtConfig;
}

const initialUser = () => {
  const item = window.localStorage.getItem('userData');

  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialImpersonate = () => {
  const item = window.localStorage.getItem('impersonateData');

  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const GetJwtConfig = () => useJwt().jwt.jwtConfig;

export const resetToken = () => {
  localStorage.removeItem('accessToken');
};

const initialState: AuthenticationState = {
  userData: initialUser(),
  impersonateData: initialImpersonate(),
  config: GetJwtConfig(),
};

// Technical debt, handle logout is the only one being used.
export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleLogin: (state, action: PayloadAction<MasterLoginOutput>) => {
      state.accessMasterToken = action.payload.accessToken;
      state.userData = action.payload;
      state.twoFactorRememberToken = action.payload.twoFactorToken;
      if (state.twoFactorRememberToken) {
        localStorage.setItem(
          state.config.storageTwoFactorRememberToken,
          action.payload.twoFactorToken
        );
      }
      localStorage.setItem(state.config.storageUserDateKeyName, JSON.stringify(action.payload));
      localStorage.setItem(state.config.storageMasterTokenKeyName, action.payload.accessToken);
    },
    handleLogout: (state) => {
      state.userData = {};
      state.impersonateData = {};
      state.accessToken = null;
      state.accessMasterToken = null;
      localStorage.removeItem(state.config.storageUserDateKeyName);
      localStorage.removeItem(state.config.storageImpersonateDataKeyName);
      localStorage.removeItem(state.config.storageMasterTokenKeyName);
      localStorage.removeItem(state.config.storageTokenKeyName);
      localStorage.removeItem(state.config.storagePermissionsKeyName);
      localStorage.removeItem(state.config.storageSignalRTokenKeyName);

      refreshBrowserTabs();
    },
    handleTenantLogin: (state, action: PayloadAction<LoginOutput>) => {
      state.accessToken = action.payload.accessToken;
      state.userData = action.payload.userData;
      localStorage.setItem(
        state.config.storageUserDateKeyName,
        JSON.stringify(action.payload.userData)
      );
      localStorage.setItem(state.config.storageTokenKeyName, action.payload.accessToken);
    },
    handleTenantSwitch: (state, action: PayloadAction<ImpersonateOutput>) => {
      state.accessToken = action.payload.accessToken;
      state.impersonateData = action.payload.impersonateData;
      state.userData = action.payload.userData;
      localStorage.setItem(
        state.config.storageUserDateKeyName,
        JSON.stringify(action.payload.userData)
      );
      localStorage.setItem(
        state.config.storageImpersonateDataKeyName,
        JSON.stringify(action.payload.impersonateData)
      );
      localStorage.setItem(state.config.storageTokenKeyName, action.payload.accessToken);
    },
    handlePermissions: (state, action: PayloadAction<CurrentUserPermissionsDto>) => {
      localStorage.setItem(state.config.storagePermissionsKeyName, JSON.stringify(action.payload));
    },
  },
});

export const {
  handleLogin, handleLogout, handleTenantLogin, handlePermissions, handleTenantSwitch,
} = authSlice.actions;

export default authSlice.reducer;

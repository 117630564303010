import type React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RefreshBroadcastChannelName } from '../Consts';

const RefreshPageListener: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof BroadcastChannel === 'undefined') {
      console.warn('BroadcastChannel is not supported in this browser.');
      return;
    }

    const broadcastChannel = new BroadcastChannel(RefreshBroadcastChannelName);

    const handleStateChangeMessage = () => {
      if (document.hidden) {
        navigate('/');
      }
    };

    broadcastChannel.addEventListener('message', handleStateChangeMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleStateChangeMessage);
    };
  }, []);

  return null;
};

export default RefreshPageListener;

// ** React Imports
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import IsRouteOrActionAllowed from '../../utility/IsRouteOrActionAllowed';

function PrivateRoute({ children, route }) {
  // ** Hooks & Vars
  const user = JSON.parse(localStorage.getItem('userData'));
  if (route) {
    let action = null;
    let publicRoute = false;

    if (route.meta) {
      action = route.meta.requiredPermission;
      publicRoute = route.meta.publicRoute;
    }
    if (!user) {
      return <Navigate to="/auth/login" />;
    }
    if (user && action && !IsRouteOrActionAllowed.IsRouteOrActionAllowed(action)) {
      return <Navigate replace to="/error" />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
}

export default PrivateRoute;

const IsRouteOrActionAllowed = (route: string): boolean => {
  if (!route) return true;
  const grantedPermissions = localStorage.getItem('permissions');

  return !!(grantedPermissions && grantedPermissions.includes(route));
};

const IsSomeRouteOrActionAllowed = (route: string[]): boolean => {
  const grantedPermissions = localStorage.getItem('permissions');

  return !!(grantedPermissions && route.some((r) => grantedPermissions.includes(r)));
};

const getFirstAllowedRoute = (routes: Map<string, string>, defaultRoute: string): string => {
  const grantedPermissions = localStorage.getItem('permissions');

  if (grantedPermissions) {
    for (const [key, value] of routes) {
      if (grantedPermissions.includes(value)) {
        return key;
      }
    }
  }

  return defaultRoute;
};

export default { IsRouteOrActionAllowed, IsSomeRouteOrActionAllowed, getFirstAllowedRoute, };

import { Navigate } from 'react-router-dom';
import Login from '../../views/Login/Login/Login';
import { ResetPassword } from '../../views/Login/Login/ResetPassword';
import routeNames from '../routeNames';
import { ForgotPassword } from '../../views/Login/Login/ForgotPassword';

// ** Merge Routes
const PublicRoutes = [
  {
    path: routeNames.login,
    element: <Login />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  },
  {
    path: routeNames.oldLogin,
    element: <Login />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  },
  {
    path: routeNames.forgotPassword,
    element: <ForgotPassword />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  },
  {
    path: `${routeNames.resetPassword}:token`,
    element: <ResetPassword />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  },
  {
    path: `${routeNames.activatePassword}:token`,
    element: <ResetPassword />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  },
  {
    path: '/*',
    element: <Navigate replace to={routeNames.login} />,
    meta: {
      layout: 'auth',
      publicRoute: true,
    },
  }
];

export default PublicRoutes;

import { type ICellRendererParams } from 'ag-grid-community';

export default function (props: ICellRendererParams) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <div className="d-flex justify-content-between align-items-center h-100">
      <span>{cellValue}</span>
      <i className="bi bi-arrow-down-short text-black" />
    </div>
  );
}

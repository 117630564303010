import React, {
  createContext, useEffect, useReducer, useState
} from 'react';
import * as signalR from '@microsoft/signalr';
import JwtService from '../../auth/jwt/jwtService';

export interface SignalRMessage {
    type: string;
    messageList?: string[];
    message?: string;
    description?: string;
}

interface SignalRContextProps {
    connection?: signalR.HubConnection;
    dispatch: React.Dispatch<Action>;
    addNotificationListener: (eventName: string, callback: (message: SignalRMessage) => void) => void;
    removeNotificationListener: (eventName: string, callback: (message: SignalRMessage) => void) => void;
}

export const SignalRContext = createContext<SignalRContextProps>({
  dispatch: () => undefined,
  addNotificationListener: () => {
  },
  removeNotificationListener: () => {
  },
});

type Action =
    | { type: 'CONNECT' }
    | { type: 'DISCONNECT' };

interface Props {
    children: React.ReactNode;
    autoConnect?: boolean;
    autoReconnect?: boolean;
    reconnectTime?: number;
    maxTries?: number;
    increaseReconnectTime?: (time: number) => number;
    url?: string;
    remoteServiceBaseUrl?: string;
    qs?: string;
}

const defaultConfig = {
  reconnectTime: 5000,
  maxTries: 8,
  increaseReconnectTime: (time: number) => time * 2,
  autoConnect: false,
  autoReconnect: true,
};

const jwtService = new JwtService();

export const SignalRProvider: React.FC<Props> = ({ children, ...props }) => {
  const [connection, setConnection] = useState<signalR.HubConnection | undefined>();
  const [notificationListeners, setNotificationListeners] = useState<Record<string, Array<(message: SignalRMessage) => void>>>({});

  // Merge the defaultConfig and the props to get the final configuration
  const config = { ...defaultConfig, ...props, };

  const prepareUrl = (url: string, signalRToken: string): string => {
    const baseUrlWithoutApi = url.replace('/api', ''); // Remove '/api' part
    let connectionUrl = `${baseUrlWithoutApi}signalr-NotificationHub`;
    const qs = 'enc_auth_token' + `=${encodeURIComponent(signalRToken)}`;
    connectionUrl += (!connectionUrl.includes('?') ? '?' : '&') + qs;

    return connectionUrl;
  };

  const configureConnection = (connection: signalR.HubConnection) => {
    let tries = 1;
    let { reconnectTime, } = config;

    const tryReconnect = () => {
      if (tries <= config.maxTries) {
        connection.start()
          .then(() => {
            reconnectTime = config.reconnectTime;
            tries = 1;
          })
          .catch(() => {
            tries += 1;
            reconnectTime = config.increaseReconnectTime(reconnectTime);
            setTimeout(tryReconnect, reconnectTime);
          });
      }
    };

    connection.onclose((e) => {
      if (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.debug('Connection closed with error:', e);
        }
        if (!config.autoReconnect) return;
        tryReconnect();
      } else if (process.env.NODE_ENV !== 'production') {
        console.debug('Disconnected');
      }
    });

    setConnection(connection);
  };

  useEffect(() => {
    if (connection) {
      connection.off('getNotification');

      connection.on('getNotification', (notificationName, messageJson) => {
        const listeners = notificationListeners[notificationName] || [];

        try {
          const message = JSON.parse(messageJson);

          // Call the listeners with the deserialized message
          listeners.forEach((listener) => {
            listener(message);
          });
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      });
    }
  }, [notificationListeners, connection]);

  const startConnection = async (url: string) => {
    const signalRToken = jwtService.getSignalRToken();

    if (!signalRToken) {
      return;
    }

    // Dirty check to prevent cookies edge case
    if (signalRToken === 'undefined') {
      return;
    }

    if (connection) {
      await connection.stop();
    }

    const start = (transport: signalR.HttpTransportType): any => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug(`Starting connection using ${signalR.HttpTransportType[transport]} transport`);
      }

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(prepareUrl(url, signalRToken), { transport, })
        .build();

      configureConnection(connection);

      return connection.start()
        .then(() => connection)
        .catch(async (error) => {
          console.debug(`Cannot start the connection using ${signalR.HttpTransportType[transport]} transport.`, error);
          return await Promise.reject(error);
        });
    };

    return start(signalR.HttpTransportType.WebSockets);
  };

  const addNotificationListener = (eventName: string, callback: (message: SignalRMessage) => void) => {
    setNotificationListeners((prevListeners) => ({
      ...prevListeners,
      [eventName]: [...(prevListeners[eventName] || []), callback],
    }));
  };

  const removeNotificationListener = (eventName: string, callback: (message: SignalRMessage) => void) => {
    const listeners = notificationListeners[eventName] || [];
    const index = listeners.indexOf(callback);
    if (index > -1) {
      listeners.splice(index, 1);
    }
    setNotificationListeners({
      ...notificationListeners,
      [eventName]: listeners,
    });
  };

  const reducer = (state: any, action: Action) => {
    switch (action.type) {
    case 'CONNECT':
      // Logic to start a new connection
      const connectionUrl = config.url || '/signalr';
      startConnection(connectionUrl);
      break;
    case 'DISCONNECT':
      // Logic to disconnect if necessary
      if (connection) {
        // Wrap the disconnection logic in a Promise
        return new Promise<void>((resolve) => {
          connection.stop().then(() => {
            setConnection(undefined);
            resolve(); // Resolve the Promise when disconnection is complete
          });
        });
      }
      break;
    }
    // Return the updated state
    return state;
  };

  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    dispatch({ type: 'CONNECT', });

    const handleStorageChange = async () => {
      // Dispatch a disconnect action and wait for it to complete
      await dispatch({ type: 'DISCONNECT', });

      dispatch({ type: 'CONNECT', });
    };

    // Here we listen to when the storage event changes so we now when we switch tenant
    window.addEventListener('storage', handleStorageChange);

    return () => {
      dispatch({ type: 'DISCONNECT', });
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  const contextValue: SignalRContextProps = {
    connection,
    dispatch,
    addNotificationListener,
    removeNotificationListener,
  };

  return (
    <SignalRContext.Provider value={contextValue}>
      {children}
    </SignalRContext.Provider>
  );
};

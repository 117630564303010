import React from 'react';

const CheckboxRenderer: React.FC<any> = ({ data, clicked, }) => (
  <div className="form-check form-switch form-check-custom form-check-solid">
    <input
      onChange={(event) => clicked(data, event.target.checked)}
      checked={data.isEnabledForTenant}
      className="form-check-input h-20px w-30px"
      type="checkbox"
      value=""
      id="flexSwitch20x30"
    />
    <label className="form-check-label" htmlFor="flexSwitch20x30">
      Enable for company
    </label>
  </div>
);

export default CheckboxRenderer;

export interface PipelineRunLogsDto {
  biHubTaskRuns: BiHubPipelineTaskRunLogDto[];
  biHubPowerBiDatasetRefreshes: BiHubPowerBiDatasetRefreshesDto[];
  masterUserEmail: string;
  tenantName: string;
  biHubExecutedBy: string;
  startedAt: Date;
  createdAt: Date;
  finishedAt: Date;
  status: number;
  powerBiDashboardsRefreshStarted: string[];
}

export interface UpdatePipelineDto {
  isEnabledForTenant: boolean;
  lastUpdatedExplanationText: string;
  pipelineTenantRunIntervalInSeconds: number;
  tenantId: number;
  tasks: BiHubTaskPutDto[];
  datasets: BiHubPowerBiDatasetPutDto[];
}

export interface BiHubPowerBiDatasetPutDto {
  id: number;
  powerBiReportId: number;
  isEnabledForTenant: boolean;
  powerBiReportDisplayName: string;
}

export interface BiHubStatusDto {
  isEnabledForTenant: boolean;
  lastUpdatedExplanationText: string;
  lastUpdateDate: Date;
  lastPipelineCreated: Date;
  pipelineIsRunning: boolean;
}

export interface BiHubPipelineTaskRunLogDto {
  id: number;
  createdAt: Date;
  masterUserId: number;
  databaseName: string;
  status: number;
  name: string;
  finishedAt: Date;
  startedAt: Date;
  action: string;
  type: number;
  error: string;
}

export interface BiHubPowerBiDatasetRefreshesDto {
  id: number;
  createdAt: Date;
  masterUserId: number;
  databaseName: string;
  status: number;
  reportName: string;
  finishedAt: Date;
  startedAt: Date;
  action: string;
  error: string;
}

export interface BiHubPipelineDto {
  tenant: {
    name: string;
  };
  isEnabledForTenant: boolean;
  lastUpdatedExplanationText: string;
  pipelineTenantRunIntervalInSeconds: number;
  tenantId: number;
  tasks: BiHubTaskDto[];
  sequentialTasks: BiHubTaskDto[];
  powerBiDatasets: PowerBiDatasetDto[];
}

export interface PowerBiDatasetDto {
  powerBiReportId: number;
  id: number;
  powerBiReportDisplayName: string;
  isEnabledForTenant: boolean;
}

export interface BiHubTaskDto {
  id: number;
  name: string;
  action: string;
  type: number;
  databaseName: string;
  sorting: number | null;
  runParallel: boolean;
  tenantCanRun: boolean;
}

export interface BiHubRunCurrentTenantPostDto {
  userId: number;
}

export interface BiHubTaskPutDto {
  id: number;
  name: string;
  action: string;
  type: number;
  sorting: number;
  runParallel: boolean;
}

export const transformPipelineTaskResponse = (response: any) => {
  response.sequentialTasks = [];

  if (response.tasks) {
    // the tasks get split here, so we can display them in two different tables
    const sequentialTasks: any = [];
    response.tasks = response.tasks.map((task: any) => {
      task.type = DataTypesMap.get(task.type);
      if (!task.runParallel) {
        sequentialTasks.push(task);
        return null; // Mark the task for removal
      }
      return task;
    }).filter((task: any) => task !== null); // Remove marked tasks
    response.sequentialTasks = sequentialTasks;
  } else {
    // Create an empty list so ag-grid can handle it
    response.tasks = [];
  }

  return response;
};

export const DataTypesMap = new Map<number, string>([
  [1, '.exe File'],
  [2, 'cmd Script'],
  [3, 'Python Script'],
  [4, 'SQL Job'],
  [5, 'SQL Stored Procedure'],
  [6, 'PowerBI Dataset Refresh']
]);

export const StatusTypesMap = new Map<number, string>([
  [-3, 'HubCommunicationFailed'],
  [-2, 'Timeout'],
  [-1, 'Error'],
  [0, 'NotStarted'],
  [1, 'Started'],
  [5, 'FinishedWithError'],
  [10, 'Finished']
]);

export const actionReplacement = 'Executes';

export type TimeOption = 'SpecificTime' | 'RangeTime';

import { notification } from 'antd';

const AppNotification = {
  success (title: string, message?: string, duration?: number) {
    notification.success({
      message: title,
      description: message,
      duration,
      placement: 'bottomRight',
    });
  },

  error (title: string, message?: string, duration?: number) {
    notification.error({
      message: title,
      description: message,
      duration,
      placement: 'bottomRight',
    });
  },

  warning (title: string, message?: string, duration?: number) {
    notification.warning({
      message: title,
      description: message,
      duration,
      placement: 'bottomRight',
    });
  },

  info (title: string, message?: string, duration?: number) {
    notification.info({
      message: title,
      description: message,
      duration,
      placement: 'bottomRight',
    });
  },
};

export default AppNotification;

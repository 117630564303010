import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Toolbar } from './components/toolbar/Toolbar';
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { PageDataProvider } from './core';

import { ThemeModeProvider } from '../partials';
import { MenuComponent } from '../assets/ts/components';
import { getUserData } from '../../app/utility/Utils';

function MasterLayout () {
  const userData = getUserData();
  const navigate = useNavigate();

  useEffect(() => {
    // Extra check, user should not be able to be logged in without userdata
    if (!userData) {
      navigate('/');
    }
  }, [userData]);

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  if (!userData) {
    return <></>;
  }

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderWrapper />

            <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
              <Toolbar />
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
}

export { MasterLayout };

import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../helpers';
import { LoginHeader } from '../../app/views/Login/Login/LoginHeader';

function AuthLayout () {
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-size-cover bgi-attachment-fixed h-100">
      <video autoPlay loop muted className="min-w-100 min-h-100 position-fixed bottom-0 end-0">
        <source src={toAbsoluteUrl('/media/videos/login-background.mp4')} type="video/mp4" />
      </video>

      <div className="d-flex justify-content-center align-items-center h-100 z-index-1">
        <div className="d-flex flex-column flex-lg-row p-10 pb-lg-20 h-lg-700px">
          <div className="w-lg-375px bg-blue-800 shadow-sm p-8 w-100">
            <LoginHeader />
          </div>
          <div className="w-lg-375px bg-white shadow-sm p-15">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export { AuthLayout };

export const MAPPING_HOST_API_URL = 'Host/DimMapping';
export const DIM_TABLE_ID = 'PORTAL_UNIQUE_INDEX';
export const CHOSEN_TABLE_DEFAULT = 'account_mapping';

export namespace MappingConsts1 {
    export const COLUMN_NAME_GL_UNIQUE = 'GL_unique';
    export const COLUMN_NAME_ACCOUNT_NR_NAME = 'GL_account_nr_name';
}

export namespace MappingConsts2 {
    export const COLUMN_NAME_GL_UNIQUE = 'PK_GL_unique';
}

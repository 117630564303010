import React, { useEffect, useRef, useState } from 'react';
import {
  type CancelDrop,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  type DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  type Modifiers,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  type SortingStrategy,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import { createPortal } from 'react-dom';
import { Container } from './components';
import { DroppableViewGroup } from '../../components/Container/DroppableViewGroup';
import { AddViewGroupButton } from './components/AddViewGroupButton';
import { Message, RtkErrorMessage } from '../../../utility/notifications/Message';
import { SortableViewGroupItem } from './components/Item/SortableViewGroupItem';
import { type ViewGroup, type ViewGroupItem, type ViewGroupTypes } from './api/types';
import { useDeleteViewGroupMutation } from './api/api';

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
};

interface Props {
  viewGroupType: ViewGroupTypes;
  editMode: boolean;
  updateItems: (items: ViewGroup[]) => void;
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  items: ViewGroup[];
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
  tenantId: string;

  getItemStyles?: (args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }) => React.CSSProperties;

  wrapperStyle?: (args: { index: number }) => React.CSSProperties;
}

// This is a prefix for the ViewGroupItem to prevent the same id's
const viewGroupItemDraggingPrefix = '-vg-item';

export function ViewGroupContainers({
  viewGroupType,
  tenantId,
  editMode,
  updateItems,
  adjustScale = false,
  cancelDrop,
  columns,
  items,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
}: Props) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [deleteViewGroup] = useDeleteViewGroupMutation();

  const recentlyMovedToNewContainer = useRef(false);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findContainer = (searchId: string) => items.find(({ id, }) => id.toString() === searchId);

  const findContainerIndex = (searchId: string) => items.findIndex(({ id, }) => id.toString() === searchId);

  const findContainerIndexByItem = (searchKey: string): number | undefined => items.findIndex((x) => x.viewGroupItems.find((t) => t.id.toString() === searchKey));

  const findViewGroup = (searchId: string) => {
    searchId = searchId.replace(viewGroupItemDraggingPrefix, '');
    for (const container of items) {
      for (const item of container.viewGroupItems) {
        if (item.id.toString() === searchId) {
          return item;
        }
      }
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const moveViewGroupItem = (array: ViewGroup[], activeContainerIndex: number, overContainerIndex: number, activeViewGroupItem: number, overViewGroupItem: number) => {
    const clone = JSON.parse(JSON.stringify(items));
    const viewGroupItem = clone[activeContainerIndex].viewGroupItems[activeViewGroupItem];
    clone[activeContainerIndex].viewGroupItems.splice(activeViewGroupItem, 1);
    clone[overContainerIndex].viewGroupItems.splice(overViewGroupItem, 0, viewGroupItem);
    return clone;
  };

  const addViewGroupItem = (activeContainerIndex: number, overContainerIndex: number, activeViewGroupItem: number) => {
    // Makes deep copy
    const clone = JSON.parse(JSON.stringify(items));
    clone[overContainerIndex].viewGroupItems.push(items[activeContainerIndex].viewGroupItems[activeViewGroupItem]);
    clone[activeContainerIndex].viewGroupItems.splice(activeViewGroupItem, 1);
    return clone;
  };

  const changeContainerName = (id: string, title: string) => {
    updateItems(items.map((x) => (x.id === parseInt(id) ? { ...x, title, } : x)));
  };

  return (
    <DndContext
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active, }) => {
        setActiveId(active.id.toString());
      }}
      onDragOver={({ active, over, }) => {
        // Todo make ondragover animations
        const overId = over?.id;

        if (!overId) {
          return;
        }

        const overContainer = findContainer(overId.toString());
        const activeContainer = findContainer(active.id.toString());

        if (!overContainer || !activeContainer) {

        }
      }}
      onDragEnd={({ active, over, }) => {
        if (active.id && over?.id && !active.id.toString().includes(viewGroupItemDraggingPrefix)) {
          const activeIndex = items.findIndex(({ id, }) => id.toString() === active.id.toString());
          const overIndex = items.findIndex(({ id, }) => id.toString() === over.id.toString());

          updateItems(arrayMove(items, activeIndex, overIndex));
        }

        if (!over) {
          setActiveId(null);
          return;
        }

        if (over.id.toString().includes(viewGroupItemDraggingPrefix) && active.id.toString().includes(viewGroupItemDraggingPrefix)) {
          const activeId = active.id.toString().replace(viewGroupItemDraggingPrefix, '');
          const overId = over.id.toString().replace(viewGroupItemDraggingPrefix, '');

          const activeContainerItemIndex = findContainerIndexByItem(activeId);
          const overContainerItemIndex = findContainerIndexByItem(overId);

          if (activeContainerItemIndex === undefined && -1 && overContainerItemIndex === undefined && -1) {
            return;
          }

          const activeItemIndex = items[activeContainerItemIndex!].viewGroupItems.findIndex((t) => t.id.toString() === activeId);
          const overItemIndex = items[overContainerItemIndex!].viewGroupItems.findIndex((t) => t.id.toString() === overId);

          if (activeItemIndex === undefined && overItemIndex === undefined) {
            return;
          }

          updateItems(moveViewGroupItem(items, activeContainerItemIndex!, overContainerItemIndex!, activeItemIndex, overItemIndex));
        }

        if (active.id.toString().includes(viewGroupItemDraggingPrefix) && !over.id.toString().includes(viewGroupItemDraggingPrefix)) {
          const activeId = active.id.toString().replace(viewGroupItemDraggingPrefix, '');
          const overId = over.id.toString();

          const activeContainerItemIndex = findContainerIndexByItem(activeId);
          const overContainerItemIndex = findContainerIndex(overId);

          if (activeContainerItemIndex === undefined) {
            return;
          }

          if (overContainerItemIndex === undefined) {
            return;
          }

          const activeItemIndex = items[activeContainerItemIndex].viewGroupItems.findIndex((t) => t.id.toString() === activeId);
          updateItems(addViewGroupItem(activeContainerItemIndex, overContainerItemIndex, activeItemIndex));
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      modifiers={modifiers}
    >
      <div
        style={{
          padding: 20,
          gridAutoFlow: vertical ? 'row' : 'column',
        }}
      >
        <SortableContext
          items={[...items]}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {items.map((container) => (
            <DroppableViewGroup
              onValueChange={changeContainerName}
              key={container.id}
              id={container.id.toString()}
              label={container.title}
              columns={columns}
              items={items}
              scrollable={scrollable}
              style={containerStyle}
              unstyled={minimal}
              editMode={editMode}
              onRemove={async () => { await handleRemove(container.id, container.viewGroupItems.length); }}
            >
              <SortableContext items={container.viewGroupItems} strategy={strategy}>
                {container.viewGroupItems.map((value, index) => (
                  <SortableViewGroupItem
                    key={value.id}
                    id={value.id.toString() + viewGroupItemDraggingPrefix}
                    index={index}
                    handle={editMode}
                    viewGroupItem={value}
                    style={getItemStyles}
                    wrapperStyle={wrapperStyle}
                    renderItem={renderItem}
                    containerId={container.toString()}
                    getIndex={findContainerIndex}
                  />
                ))}
              </SortableContext>
            </DroppableViewGroup>
          ))}
          {!editMode &&
            (
              <AddViewGroupButton
                verticalSorting={items.length + 1}
                tenantId={tenantId}
                type={viewGroupType}
              />
            )}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? items.find((t) => t.id.toString() === activeId)
              ? renderContainerDragOverlay()
              : renderSortableItemDragOverlay(findViewGroup(activeId))
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(viewGroupItem: ViewGroupItem | undefined) {
    let content = (<div />);

    if (viewGroupItem !== undefined) {
      content = (
        <SortableViewGroupItem
          id={viewGroupItem.id.toString()}
          handle={editMode}
          style={getItemStyles}
          wrapperStyle={wrapperStyle}
          renderItem={renderItem}
          viewGroupItem={viewGroupItem}
        />
      );
    }

    return (
      <>
        {content}
      </>
    );
  }

  function renderContainerDragOverlay() {
    const viewGroupItem = items.find((t) => t.id.toString() === activeId);

    return (
      <Container
        id={viewGroupItem!.id.toString()}
        label={viewGroupItem!.title}
        columns={columns}
        style={{
          height: '100%',
        }}
        shadow
        unstyled={false}
      >
        {viewGroupItem!.viewGroupItems.map((value, index) => (
          <SortableViewGroupItem
            key={value.id}
            id={value.id.toString() + viewGroupItemDraggingPrefix}
            index={index}
            handle={editMode}
            viewGroupItem={value}
            style={getItemStyles}
            wrapperStyle={wrapperStyle}
            renderItem={renderItem}
            containerId={viewGroupItem!.id.toString()}
            getIndex={findContainerIndex}
          />
        ))}
      </Container>
    );
  }

  async function handleRemove(containerID: UniqueIdentifier, viewGroupItemsLength: number) {
    if (viewGroupItemsLength === 0) {
      const result = await Message('Confirm', 'Are you sure you want to delete this view-group', 'warning');

      if (result.isConfirmed) {
        deleteViewGroup(containerID)
          .unwrap()
          .then()
          .catch(async (error) => { await RtkErrorMessage('Deleting view-group failed', error); });
      }
      return;
    }

    Message('Action failed', 'The view-group must be empty before it can be deleted', 'error');
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import { ViewGroupContainers } from './ViewGroupContainers';
import { useEditViewGroupMutation } from './api/api';
import { type ViewGroup, type ViewGroups, type ViewGroupTypes } from './api/types';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import AppNotification from '../../../utility/notifications/AppNotifications';

interface Props {
    viewGroupType: ViewGroupTypes;
    viewGroups: ViewGroup[];
}

function ViewGroupOverview ({ viewGroupType, viewGroups, }: Props) {
  const params = useParams();

  const [editViewGroup] = useEditViewGroupMutation();
  const [items, setItems] = useState<ViewGroup[]>(viewGroups);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setItems(viewGroups);
  }, [viewGroups]);

  const putViewGroupData = () => {
    if (!items) {
      return;
    }

    const viewGroupsDto: ViewGroups = {
      tenantId: params.id!,
      ViewGroups: items.map((viewGroup, index) => ({
        ...viewGroup,
        type: viewGroupType,
        verticalSorting: index,
        viewGroupItems: viewGroup.viewGroupItems.map((viewGroupItem, index) => ({
          ...viewGroupItem,
          horizontalSorting: index,
        })),
      })),
    };

    editViewGroup(viewGroupsDto)
      .unwrap()
      .then(() => {
        AppNotification.success('View-groups', 'View-groups and reports updated successfully');
        flipEditMode();
      })
      .catch(async (error) => { await RtkErrorMessage('Editing view-groups failed', error); });
  };

  const flipEditMode = () => {
    setEditMode(!editMode);
  };

  const updateItems = (viewGroups: ViewGroup[]) => {
    unstable_batchedUpdates(() => {
      setItems(viewGroups);
    });
  };

  const cancelUpdate = () => {
    if (viewGroups) {
      setItems(viewGroups);
    }
    flipEditMode();
  };

  return (
    <div
      className="px-9"
      style={{ marginTop: '-100px', }}
    >
      <div className="d-flex justify-content-end">
        {editMode
          ? (
            <div className="">
              <button type="button" className="btn btn-light mx-2" onClick={cancelUpdate}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={putViewGroupData}>
                Save
              </button>
            </div>
          )
          : (
            <button type="button" className="btn btn-primary" onClick={flipEditMode}>
              Move reports
            </button>
          )}
      </div>
      <ViewGroupContainers
        viewGroupType={viewGroupType}
        tenantId={params.id!}
        editMode={editMode}
        updateItems={(items) => { updateItems(items); }}
        items={items}
        columns={5}
        strategy={rectSortingStrategy}
        wrapperStyle={() => ({
          width: 200,
        })}
        vertical
      />
    </div>
  );
}

export { ViewGroupOverview };

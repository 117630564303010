// ** React Imports
import { Navigate } from 'react-router-dom';
import IsRouteOrActionAllowed from '../../utility/IsRouteOrActionAllowed';
import routeNames from '../routeNames';
import { Tenant_Dashboards, Tenant_ManagementReports_View } from '../../constants/permissions';
import { getUserData } from '../../utility/Utils';

function TenantFallbackRoute () {
  const user = getUserData();

  if (!user) {
    return <Navigate to={routeNames.login} />;
  } if (IsRouteOrActionAllowed.IsRouteOrActionAllowed(Tenant_ManagementReports_View)) {
    return <Navigate to={routeNames.ssrs} />;
  } if (IsRouteOrActionAllowed.IsRouteOrActionAllowed(Tenant_Dashboards)) {
    return <Navigate to={routeNames.powerBi} />;
  }
  return <Navigate to={routeNames.faq} />;
}

export default TenantFallbackRoute;

import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useJwt from '../../auth/jwt/useJwt';
import { type LoginOutput, type MasterUserTenant } from '../../types';
import SelectTenantSlider from '../components/core/SelectTenantSlider';
import routeNames from '../../routing/routeNames';
import { emptySplitApi } from '../../utility/emptySplitApi';

interface ISelectTenantModalProps {
  show: boolean;
  showHostEnvLink: boolean;
  tenants: MasterUserTenant[];
  handleClose: () => void;
}

function SelectTenantModal ({
  show,
  handleClose,
  tenants,
  showHostEnvLink,
}: ISelectTenantModalProps) {
  const { jwt, } = useJwt();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentTenant, setCurrentTenant] = useState<MasterUserTenant[]>(tenants);
  const dispatch = useDispatch();

  const selectHost = () => {
    jwt.login().then((res) => {
      if (res.status === 200) {
        jwt.setPermissions(res.data.data.userData.grantedPermissions);

        delete res.data.data.userData.grantedPermissions;
        const result: LoginOutput = {
          accessToken: res.data.data.accessToken,
          encryptedAccessToken: res.data.data.encryptedAccessToken,
          userData: { ...res.data.data.userData, hasAccessToHostEnvironment: showHostEnvLink, tenants: currentTenant.map((t) => t.id), },
        };

        jwt.handleLogin(result);
        dispatch(emptySplitApi.util.resetApiState());
        navigate(routeNames.tenant);
        handleClose();
      }
    });
  };

  const updateArrayValue = (id: number) => {
    let favouriteDto;

    const newTenants = currentTenant.map((tenant) => {
      if (tenant.id === id) {
        favouriteDto = {
          tenantId: tenant.id,
          isFavorite: !tenant.isFavorite,
        };
        return {
          ...tenant,
          isFavorite: !tenant.isFavorite,
        };
      }
      return tenant;
    });

    setCurrentTenant(newTenants);
    jwt
      .setTenantFavourite(favouriteDto)
      .then(() => { })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
        }
      });
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      contentClassName="bg-transparent"
    >
      <Modal.Header closeButton className="border-bottom-0 pb-0" closeVariant="white">
        {showHostEnvLink && (
          <div onClick={selectHost} role="button" className="text-white fs-1 fw-bolder">
            <i className="las la-tachometer-alt mx-1 text-white fs-2qx align-middle" />
            Go to host environment
          </div>
        )}
      </Modal.Header>

      <Modal.Body className="pt-0">
        {!loading && (
          <SelectTenantSlider
            handleClose={handleClose}
            showHostEnvLink
            onClick={(a: number) => {
              updateArrayValue(a);
            }}
            availableTenants={currentTenant}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SelectTenantModal;

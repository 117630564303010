import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useGetDimMappingQuery, useGetMissingMappingsQuery, useUpdateDimMappingMutation } from './api/api';
import { MixedWidget } from '../content-manager/components/MixedWidget';
import * as Permission from '../../constants/permissions';
import { PageTitle } from '../../../_metronic/layout/core';
import Page from '../components/Page';
import usePreventionLeave from '../../utility/hooks/usePreventionLeave';
import { Message, RtkErrorMessage } from '../../utility/notifications/Message';
import { type DimTableDto } from './api/types';
import { KTCard } from '../../../_metronic/helpers';
import MissingMappingGrid from './MissingMappingGrid';
import { useTransactionsDimMapping } from './hooks/useTransactions';
import AvailableDimTables from './components/AvailableDimTablesSelect';
import { CHOSEN_TABLE_DEFAULT, DIM_TABLE_ID } from './api/const';
import AppGridCrud, { type AppGridHandler } from '../components/grid/AppGridCRUD';
import AppToolTip from '../components/core/AppToolTip';
import { popoverElementMapping } from './components/PopoverElementMapping';
import ActionButton from '../components/buttons/ActionButton';
import { createAgColumnsArray, rowsDropdownValuesAreValid } from '../../utility/AgUtils';
import { extractSpecificParameter, replaceEmptyStrings } from '../../utility/Utils';
import { type UpdateDimTableRequestDto } from '../../api/data-contracts';
import AppNotification from '../../utility/notifications/AppNotifications';

function MappingLayout() {
  const params = useParams();
  const ref = useRef<AppGridHandler>(null);
  const [chosenTable, setChosenTable] = useState<string>(CHOSEN_TABLE_DEFAULT);
  const [updateDimMapping, { isLoading: updateLoading, }] = useUpdateDimMappingMutation();

  const { data: missingMappings, } = useGetMissingMappingsQuery(null);
  const { data, isLoading: dimLoading, error, } = useGetDimMappingQuery({ tenantId: params.id, dimValue: chosenTable, });

  const {
    deleteRow, insertRow, updateRow, clearTransactions, getContextMenuItems, processDataFromClipboard, undoDelete, transactions,
  } = useTransactionsDimMapping(DIM_TABLE_ID);

  const [isEdited, setIsEdited] = useState<'Always' | null>(null);
  const [clonedData, setClonedData] = useState<DimTableDto>();

  const hasPendingTransactions = useMemo(() => (transactions.remove && transactions.remove.length > 0) ||
    (transactions.update && transactions.update.length > 0) ||
    (transactions.add && transactions.add.length > 0), [transactions]);

  useEffect(() => {
    if (hasPendingTransactions) {
      setIsEdited('Always');
    } else {
      setIsEdited(null);
    }
  }, [hasPendingTransactions]);

  usePreventionLeave(isEdited);

  const onchange = async (e: any) => {
    if (hasPendingTransactions) {
      const result = await Message('Confirm', 'All changes will be lost if you continue', 'warning');
      if (result.isConfirmed) {
        setChosenTable(e);
        clearTransactions();
      }
    } else {
      setChosenTable(e);
      clearTransactions();
    }
  };

  const handleSave = useCallback(async () => {
    if (!params.id || !clonedData) {
      return;
    }

    const rowsToAdd = transactions.add ? transactions.add.map((item: any) => replaceEmptyStrings(item)) : null;
    const rowsToUpdate = transactions.update ? transactions.update.map((item: any) => replaceEmptyStrings(item)) : null;
    const rowsToDelete = transactions.remove ? extractSpecificParameter(transactions.remove, DIM_TABLE_ID) : null;

    const updateRequest: UpdateDimTableRequestDto = {
      tenantId: parseInt(params.id),
      dimMappingTable: chosenTable,
      rowsToAdd,
      rowIdsToDelete: rowsToDelete,
      rowsToUpdate,
    };

    if (!rowsDropdownValuesAreValid(rowsToUpdate, clonedData.columns)) {
      return;
    }

    if (!rowsDropdownValuesAreValid(rowsToAdd, clonedData.columns)) {
      return;
    }

    await updateDimMapping(updateRequest)
      .unwrap()
      .then(() => {
        AppNotification.success('Dim mapping', 'Update is success');
        clearTransactions();
      })
      .catch(async (error) => {
        if (!error.data.exception) {
          await Message('Updating Dim mapping failed', error.message, 'error');
        }
        try {
          const errorObj: { message: string; id: string; title: string } = JSON.parse(error.data.exception);
          await Message(errorObj.title, errorObj.message, 'error');
          errorObj.id && console.error(errorObj.id);
        } catch {
          await Message('Updating Dim mapping failed', error.data.exception, 'error');
        }
      });
  }, [chosenTable, clearTransactions, params.id, transactions, updateDimMapping]);

  useEffect(() => {
    setClonedData(cloneDeep(data));
  }, [data]);

  if (error) {
    RtkErrorMessage('Fetching mapping failed', error);
  }

  const memoizedColumns = useMemo(() => {
    if (clonedData?.columns) {
      return createAgColumnsArray(clonedData.columns);
    } return [];
  }, [clonedData]);

  const memoizedAppMappingToolbar = useMemo(() => {
    if (data) {
      return (
        <div className="card-header pt-6">
          <div className="d-flex align-content-center">
            <div className="alert alert-primary" role="alert">
              {data.database}
            </div>

            <div className="my-1 mx-4">
              <AppToolTip popoverElement={popoverElementMapping} text="Shortcuts" />
            </div>

            <div className="my-1">
              <ActionButton
                color="primary"
                text="Add new row"
                fontIcon="fa fa-plus"
                state={false}
                onClick={() => ref.current && ref.current.addNewRow()}
              />
            </div>
          </div>

          <div className="d-flex">
            {transactions.remove && transactions.remove.length > 0
              ? (
                <div>
                  <ActionButton
                    color="warning"
                    text="Undo delete"
                    fontIcon="fa fa-undo"
                    state={updateLoading}
                    onClick={() => ref.current && ref.current.undo()}
                  />
                </div>
              )
              : null}

            <div>
              <ActionButton
                color="primary"
                text="Save"
                fontIcon="fa fa-save"
                state={updateLoading}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      );
    }
  }, [updateLoading, handleSave, data, transactions.remove]);

  return (
    <Page title="Mapping page" permission={Permission.Host_Mapping_View}>
      <PageTitle>Mapping page</PageTitle>
      <KTCard>
        <MixedWidget
          className=""
          color="blue-600"
          heigth="h-100px"
          buttonComponent={
            AvailableDimTables(onchange)
          }
          title={chosenTable}
        />

        {dimLoading &&
          (
            <div className="card-body d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        {error
          ? (
            <div className="card-body d-flex justify-content-center">
              Fetching mapping failed
            </div>
          )
          : (
            <>
              {chosenTable === 'account_mapping' && data && missingMappings &&
                <MissingMappingGrid data={missingMappings} filterProp={data.database} />}
              {clonedData &&
                (
                  <>
                    {memoizedAppMappingToolbar}

                    <AppGridCrud
                      ref={ref}
                      data={clonedData.rows}
                      isLoading={dimLoading}
                      rowIdParam={DIM_TABLE_ID}
                      columns={memoizedColumns}
                      height="700px"
                      // All transaction logic
                      deleteRow={deleteRow}
                      insertRow={insertRow}
                      updateRow={updateRow}
                      getContextMenuItems={getContextMenuItems}
                      processDataFromClipboard={processDataFromClipboard}
                      undoDelete={undoDelete}
                    />
                  </>
                )}
            </>
          )}
      </KTCard>
    </Page>
  );
}

export { MappingLayout };

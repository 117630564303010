// ** React Imports
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import routeNames from '../routeNames';
import { getUserData, getUserPermissions } from '../../utility/Utils';
import { Tenant_Dashboards, Tenant_ManagementReports_View } from '../../constants/permissions';

function PublicRoute({ children, route }) {
  if (route) {
    const user = getUserData();
    const permissions = getUserPermissions();
    const publicRoute = route.meta && route.meta.publicRoute;

    // Hardcoded part for if the user is logged in and tries to access the reset password page, it will NOT redirect to the dashboard
    if (route.path.includes(routeNames.resetPassword) && user) {
      return <Suspense fallback={null}>{children}</Suspense>;
    }

    if (user?.isHost && publicRoute) {
      return <Navigate to={routeNames.tenant} />;
    }
    if (user?.isHost === false && publicRoute) {
      if (permissions.includes(Tenant_ManagementReports_View)) {
        return <Navigate to={routeNames.ssrs} />;
      } if (permissions.includes(Tenant_Dashboards)) {
        return <Navigate to={routeNames.powerBi} />;
      }
      return <Navigate to={routeNames.faq} />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
}

export default PublicRoute;

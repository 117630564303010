import React from 'react';

export interface IAppFormErrorProps {
  error: string;
  visible: boolean;
}

export default function AppFormError (props: IAppFormErrorProps) {
  if (!props.error || !props.visible) return null;
  return (
    <div className="fv-plugins-message-container">
      <div className="fv-help-block">
        <span role="alert">{props.error}</span>
      </div>
    </div>
  );
}

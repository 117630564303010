import React, { type FC, useEffect, useState } from 'react';
import { type AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../../app/views/components/buttons/ActionButton';
import { isObjectEmptyOrUndefined } from '../../../helpers';
import SelectTenantModal from '../../../../app/views/modals/SelectTenantModal';
import { type MasterUserTenant } from '../../../../app/types';
import { getImpersonateData, getUserData } from '../../../../app/utility/Utils';
import useJwt from '../../../../app/auth/jwt/useJwt';
import { emptySplitApi } from '../../../../app/utility/emptySplitApi';
import { HeaderUserMenu } from '../../../partials/layout/header-menus/HeaderUserMenu';
import routeNames from '../../../../app/routing/routeNames';

const Topbar: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jwt, } = useJwt();
  const [loading, setLoading] = useState(false);
  const booleanValue = useSelector((state: any) => state.backToContentManagerPage.booleanValue);

  const [showSelectTenantModal, setShowSelectTenantModal] = useState<boolean>(false);
  const [masterTenant, setMasterTenant] = useState<{
        hasAccessToHostEnvironment: boolean;
        tenants: MasterUserTenant[];
    }>({
      hasAccessToHostEnvironment: false,
      tenants: [],
    });
  const userData = getUserData();
  const impersonateData = getImpersonateData();

  useEffect(() => {
    if (userData == null) {
      navigate(routeNames.login);
    }
  }, [userData, navigate]);

  function isMultiTenant (): boolean {
    if (userData?.tenants) {
      if (userData.tenants.length > 1) {
        return true;
      } if (
        userData.tenants.length >= 1 &&
                (userData.hasAccessToHostEnvironment || userData.isHost)
      ) {
        return true;
      }
    }
    return false;
  }

  const selectHost = () => {
    setLoading(true);
    jwt
      .login()
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          jwt.setPermissions(res.data.data.userData.grantedPermissions);

          delete res.data.data.userData.grantedPermissions;
          const result = {
            accessToken: res.data.data.accessToken,
            impersonateData: {},
            UserId: 1,
            GrantedPermissions: res.data.grantedPermissions,
            userData: { ...userData, isHost: true, },
          };

          jwt.setTenant(result);
          dispatch(emptySplitApi.util.resetApiState());

          if (booleanValue) {
            navigate(`${routeNames.tenant}/${impersonateData.tenantId}${routeNames.contentPage}`);
          } else {
            navigate(routeNames.tenant);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTenants = () => {
    setLoading(true);
    jwt
      .getTenantsForMasterUser()
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          setShowSelectTenantModal(true);
          setMasterTenant(res.data.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {!isObjectEmptyOrUndefined(impersonateData)
? (
  <div className="d-flex align-items-center py-1">
    <ActionButton
      color="danger"
      fontIcon="fas la-backward"
      text="Back to host env"
      state={loading}
      onClick={selectHost}
          />
  </div>
      )
: (
        isMultiTenant() && (
          <div className="d-flex align-items-center py-1">
            <ActionButton
              color="primary"
              text="Switch Company"
              fontIcon="fas la-random"
              state={loading}
              onClick={getTenants}
            />
          </div>
        )
      )}

      <div className="d-flex align-items-center py-1" id="kt_header_user_menu_toggle">
        <div
          className="btn btn-dark btn-sm btn-flex px-4 me-2 btn-user-profile"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <i className="fas fa-user-circle" />
          {userData?.firstName && userData?.surName && (
            <>
              {userData?.firstName}
              {' '}
              {userData?.surName}
            </>
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {showSelectTenantModal && (
        <SelectTenantModal
          show
          showHostEnvLink={masterTenant?.hasAccessToHostEnvironment}
          tenants={masterTenant?.tenants}
          handleClose={() => { setShowSelectTenantModal(false); }}
        />
      )}
    </div>
  );
};

export { Topbar };

import React from 'react';
import { Modal } from 'react-bootstrap';
import { useListView } from '../../utility/ListView';

interface Props {
    formModal: React.ReactNode;
}

function GenericModal ({ formModal, }: Props) {
  const { toggle, } = useListView();

  return (
    <Modal
      size="lg"
      tabIndex={-1}
      aria-hidden="true"
      show
      backdrop="static"
      dialogClassName="modal-dialog modal-dialog-centered"
      onHide={() => { toggle(null); }}
      contentClassName="bg-transparent"
    >
      <div className="modal-content">
        {formModal}
      </div>
    </Modal>
  );
}

export { GenericModal };

import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Message } from '../../../utility/notifications/Message';
import { useListView } from '../../../utility/ListView';

interface Props {
    showError: boolean;
}

export function ButtonComponent ({ showError, }: Props) {
  const { toggle, } = useListView();

  const onClick = () => {
    if (showError) {
      Message('No view-groups found', 'Please create a view-group first', 'error', {});
    } else {
      toggle(null);
    }
  };

  return (
    <button type="button" className="btn btn-primary" onClick={onClick}>
      <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
      Create report
    </button>
  );
}

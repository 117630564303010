import { type FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { type TooltipProps } from 'react-bootstrap/Tooltip';
import { type buttonColors } from '../../../types';

interface Props {
    stringList: string[];
    maxLength?: number;
    badgeColor?: buttonColors;
}

const TableBadges: FC<Props> = ({ stringList, maxLength = 5, badgeColor = 'primary', }) => {
  const renderTooltip = (props: TooltipProps) => {
    const rows = [];
    for (let i = maxLength; i < stringList.length; i += 1) {
      rows.push(
        <div key={i}>{stringList[i]}</div>
      );
    }

    return (
      <Tooltip id="button-tooltip" {...props}>
        {rows}
      </Tooltip>
    );
  };

  return (
    <div>
      {stringList.map((item, index) => {
        if (index < maxLength) {
          return (
            <div
              key={index}
              className={`badge my-2 badge-light-${badgeColor} d-inline-block mx-1`}
            >
              {item}
            </div>
          );
        }
        if (index === maxLength) {
          return (
            <OverlayTrigger
              key={index}
              placement="right"
              overlay={renderTooltip}
            >
              <div className="badge fw-bolder badge-light-success">more</div>
            </OverlayTrigger>
          );
        }
      })}
    </div>
  );
};

export { TableBadges };

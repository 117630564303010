import { emptySplitApi } from '../../../../utility/emptySplitApi';
import { PowerBI_Host_Route, PowerBiApi_Host_Route } from '../../../../constants/routes';
import {
  type CreateMigrateReportDto, type PowerBIReportDropdownDto,
  type PowerBiReportDto
} from './types';
import { makeObjectSelectReady } from '../../../../utility/Utils';

const importFormAPi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    addNewPowerBiReport: build.mutation({
      query (data: PowerBiReportDto) {
        return {
          url: PowerBI_Host_Route,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['PowerBi'],
    }),
    EditPowerBiReport: build.mutation({
      query (data: PowerBiReportDto) {
        return {
          url: PowerBI_Host_Route,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['PowerBi'],
    }),
    getPowerBiReportById: build.query({
      query: (id) => `${PowerBI_Host_Route}Editable/${id}`,
      transformResponse: (rawResult: { data: PowerBiReportDto }) => rawResult.data,
      providesTags: ['PowerBi'],
    }),
    // This gets all the available reports from the database
    getAvailablePowerBiReportsDropdown: build.query<PowerBIReportDropdownDto[], number>({
      query: (tenantId) => `${PowerBI_Host_Route}Dropdown/${tenantId}`,
      transformResponse: (rawResult: { data: { reports: PowerBIReportDropdownDto[] } }) => makeObjectSelectReady(rawResult.data.reports, 'id', 'name'),
      providesTags: ['PowerBi'],
    }),
    // This gets all the reports which are available with the linked powerBi account
    getPowerBiReportsForDropdown: build.query<Array<{ value: string; label: string }>, number>({
      query: (id) => `${PowerBiApi_Host_Route}${id}`,
      transformResponse: (rawResult: any) => makeObjectSelectReady(rawResult.reports, 'apiReportId', 'name'),
      providesTags: ['PowerBi'],
    }),
    deletePowerBiReport: build.mutation({
      query: (id) => ({
        url: PowerBI_Host_Route + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['PowerBi'],
    }),
    postMigrateReport: build.mutation({
      query: (data: CreateMigrateReportDto) => ({
        url: `${PowerBI_Host_Route}MigrateReports`,
        method: 'POST',
        body: data,
      }),
      transformResponse: (rawResult: any) => ({
        acceptedPowerbiReports: [...rawResult.acceptedPowerbiReports, ...rawResult.acceptedPaginatedReports],
        rejectedPowerbiReports: [...rawResult.rejectedPowerbiReports, ...rawResult.rejectedPaginatedReports],
      }),
      invalidatesTags: ['MigrateReport'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddNewPowerBiReportMutation,
  useGetPowerBiReportByIdQuery,
  useEditPowerBiReportMutation,
  useDeletePowerBiReportMutation,
  useGetPowerBiReportsForDropdownQuery,
  usePostMigrateReportMutation,
  useGetAvailablePowerBiReportsDropdownQuery,
} = importFormAPi;

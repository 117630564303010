/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../../../../app/redux/authentication';
import { emptySplitApi } from '../../../../app/utility/emptySplitApi';
import { getImpersonateData, getUserData } from '../../../../app/utility/Utils';
import { isObjectEmptyOrUndefined } from '../../../helpers';
import routeNames from '../../../../app/routing/routeNames';

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = getUserData();
  const impersonateData = getImpersonateData();

  const logout = async () => {
    dispatch(emptySplitApi.util.resetApiState());
    dispatch(handleLogout());
    navigate(routeNames.login);
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex flex align-items-center fs-5">
              {userData?.firstName}
              {' '}
              {userData?.surName}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {userData?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      {!isObjectEmptyOrUndefined(impersonateData) && (
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex flex align-items-center fs-5">
                Impersonated as:
              </div>
              <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
                {impersonateData.emailAdress}
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="menu-item px-5">
        <Link to="/settings" className="menu-link px-5">
          Settings
        </Link>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };

import { Popover } from 'react-bootstrap';

const popoverElementBiHub = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">  The column 'Executes' refers to the file or command the task will execute.</Popover.Header>
    <Popover.Body>
      <div>
        If your task Type is. exe, cmd script or Python script, the value of 'Executes' must be the
        {' '}
        <p className="badge badge-secondary">path to the file or script</p>
        .
        E.g. C:\PythonScriptsFolder\script.py or C:\APIsFolder\ExactOnline.exe .
      </div>

      <div>
        If your task Type is SQL stored procedure or SQL Job, the value of 'Executes' must be the
        {' '}
        <p className="badge badge-secondary "> name of your stored procedure or job </p>
        . Brackets like [] are allowed, but not necessary.
        {/* eslint-disable-next-line max-len */}
        E.g. [dbo].[storedprocedurename] or dbo.storedprocedurename or [Dev2021-002 Demo] or Dev2021-002 Demo.
      </div>
    </Popover.Body>
  </Popover>
);

export { popoverElementBiHub };

import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { MixedWidget } from '../components/MixedWidget';
import { useGetSsrsViewGroupsQuery } from '../view-group/api/api';
import { ViewGroupOverview } from '../view-group/viewGroupOverview';
import { GenericModal } from '../GenericModal';
import { ListViewProvider, useListView } from '../../../utility/ListView';
import { CreateEditForm } from './CreateEditForm';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import { ButtonComponent } from '../components/ButtonComponent';
import { TenantListViewProvider } from '../../tenant/TenantListView';
import { ManageContentHeader } from '../ManageContentHeader';
import { PageTitle } from '../../../../_metronic/layout/core';

export function SsrsPageView() {
  const { isShowing, } = useListView();
  const params = useParams();

  const number: number = parseInt(params.id!);

  const {
    data, isSuccess, isLoading, error,
  } = useGetSsrsViewGroupsQuery(number);

  let content;

  if (isLoading) {
    content = (
      <div className="card-body d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (data && isSuccess) {
    content = <ViewGroupOverview viewGroups={data} viewGroupType={2} />;
  } else if (error) {
    RtkErrorMessage('Fetching view-groups failed', error);

    content = (
      <div className="card-body d-flex justify-content-center">
        <h2>Fetching view-groups failed</h2>
      </div>
    );
  }

  return (
    <>
      <ManageContentHeader />
      <div className="row g-5 g-xxl-8">
        <MixedWidget
          title="SSRS reports"
          className=""
          color="blue-200"
          bodyComp={content}
          buttonComponent={data && <ButtonComponent showError={data.length <= 0} />}
        />
      </div>

      {isShowing && <GenericModal formModal={<CreateEditForm />} />}
    </>
  );
}

const SsrsContentWrapper: React.FunctionComponent = () => (
  <>
    <PageTitle>SSRS reports</PageTitle>
    <TenantListViewProvider>
      <ListViewProvider>
        <SsrsPageView />
      </ListViewProvider>
    </TenantListViewProvider>
  </>
);

export default SsrsContentWrapper;

import { type createUserDto, type User } from './types';
import { emptySplitApi } from '../../../utility/emptySplitApi';
import { type GetRequestFilter, type GetRequestFilterTenant } from '../../../types';
import { User_Common_Route, User_Host_Route } from '../../../constants/routes';
import { type MasterUserDtoPagedResultDto } from '../../../api/data-contracts';
import { Api } from '../../../api/ApiRoute';
import { replacePathParams } from '../../../utility/Utils';

const tenantApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: (input: GetRequestFilter) => ({
        url: `${User_Common_Route}paged`,
        method: 'GET',
        params: input,
      }),
      providesTags: ['User'],
    }),
    getUserById: build.query({
      query: (id) => User_Common_Route + id,
      transformResponse: (rawResult: { data: User }) => rawResult.data,
      providesTags: ['User'],
    }),
    getImpersonateTenantUsers: build.query({
      query: (input: GetRequestFilterTenant) => ({
        url: `${User_Host_Route}ImpersonateTenantUsers`,
        method: 'GET',
        params: input,
      }),
      providesTags: ['User'],
    }),
    addNewUser: build.mutation({
      query (data: createUserDto) {
        return {
          url: User_Common_Route,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    EditUser: build.mutation({
      query (data: createUserDto) {
        return {
          url: User_Common_Route,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    DeleteUser: build.mutation({
      query (id) {
        return {
          url: User_Common_Route + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),

    // Master user routes
    getMasterUsers: build.query({
      query: (input: GetRequestFilter) => ({
        url: Api.V1HostMasterUsersPagedList.BaseRequest.url,
        method: Api.V1HostMasterUsersPagedList.BaseRequest.method,
        params: input,
      }),
      transformResponse: (rawResult: { data: MasterUserDtoPagedResultDto }) => rawResult.data,
      providesTags: ['User'],
    }),

    resetMasterUserEmail: build.query({
      query: (input: Api.V1HostMasterUsersResetpasswordDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostMasterUsersResetpasswordDetail.BaseRequest.url, input),
        method: Api.V1HostMasterUsersResetpasswordDetail.BaseRequest.method,
      }),
    }),

    deleteMasterUser: build.mutation({
      query: (input: Api.V1HostMasterUsersResetpasswordDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostMasterUsersDelete.BaseRequest.url, input),
        method: Api.V1HostMasterUsersDelete.BaseRequest.method,
      }),
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserByIdQuery,
  useGetUsersQuery,
  useGetMasterUsersQuery,
  useEditUserMutation,
  useAddNewUserMutation,
  useDeleteUserMutation,
  useGetImpersonateTenantUsersQuery,
  useLazyResetMasterUserEmailQuery,
  useDeleteMasterUserMutation,
} = tenantApi;

import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Accordion } from 'react-bootstrap';
import {
  Field, FieldArray, type FieldArrayRenderProps, type FormikValues, useFormikContext
} from 'formik';
import { useState } from 'react';
import AppTextInput from '../../components/forms/AppTextInput';
import AppFormDropdown from '../../components/forms/AppFormDropdown';
import { columnIdentifierArray, type ImportFormFieldDto } from '../importForms/api/types';
import { convertDataTypeParameter, convertDataTypeParameterToHtmlInput } from '../../../utility/Utils';

interface Props {
  id: number;
  importFormField: ImportFormFieldDto;
  index: number;
  renderProp: FieldArrayRenderProps;
  name: string;
  nestedName: string;
  values: FormikValues;
  dataType: Map<number, string>;
}

export function SortableImportFormItem({
  id,
  importFormField,
  index,
  renderProp,
  name,
  nestedName,
  values,
  dataType,
}: Props) {
  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id, });

  const { setFieldValue, } = useFormikContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDataTypeChange = (value: number) => {
    const selectedDataType = convertDataTypeParameter(value);
    if (selectedDataType === 'Date') {
      setFieldValue(`${name}.${index}.hasDefaultValues`, false);
    }
    setFieldValue(`${name}.${index}.columnIdentifier`, columnIdentifierArray.filter((x) => x.includes(selectedDataType))[0]);
  };

  const handlePaste = (arrayHelpersParamValues: FieldArrayRenderProps, pastedData: string) => {
    const parsedData = pastedData.split(/\r\n|\n|\r/).filter((line: string) => line.trim() !== '');

    parsedData.forEach(value => {
      arrayHelpersParamValues.push({ value });
    });
  };


  return (
    <div ref={setNodeRef} style={style}>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h4>{importFormField.name}</h4>
            <i className=" btn bi bi-grip-vertical fs-2" {...listeners} {...attributes} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row align-items-end">
            <div className="col-12">
              <AppTextInput
                type="text"
                name={`${name}[${index}].name`}
                label="Field name"
                placeholder="Field name"
                required={false}
                appendIcon="fa-solid fa-trash"
                onClick={() => renderProp.remove(index)}
              />
            </div>

            <div className="col-6 mt-5">
              <AppFormDropdown
                name={`${name}.${index}.datatype`}
                label="Data type"
                optionsMap={dataType}
                onChange={
                  (event: string | number) => {
                    handleDataTypeChange(parseInt(event.toString()));
                  }
                }
              />
            </div>

            <div className="col-6 mt-5">
              <AppFormDropdown
                name={`${name}.${index}.columnIdentifier`}
                label="Column identifier"
                optionsList={columnIdentifierArray.filter((x) => x.includes(convertDataTypeParameter(values.fields[index].datatype)))}
              />
            </div>

            {values[name][index].datatype !== 2 && (
              <div className="col-12 mt-5">
                <div className="form-check form-check-custom form-check-solid">
                  <Field
                    className="form-check-input"
                    type="checkbox"
                    name={`${name}.${index}.hasDefaultValues`}
                  />

                  <label className="form-check-label">
                    <div className="fw-bolder text-gray-800">Use default values</div>
                  </label>
                </div>
              </div>
            )}

            {values.fields[index].hasDefaultValues && (
              <FieldArray
                name={`${name}.${index}.${nestedName}`}
                render={(arrayHelpersParamValues) => (
                  <div>
                    {values.fields[index].values &&
                      values.fields[index].values.length > 0 &&
                      values.fields[index].values.map((field: any, valuesIndex: number) => (
                        <div key={valuesIndex}>
                          <div className="row align-items-end">
                            <div className="fv-row mt-5">
                              <AppTextInput
                                type={convertDataTypeParameterToHtmlInput(values.fields[index].datatype)}
                                name={`${name}.${index}.${nestedName}.${valuesIndex}.value`}
                                label=""
                                placeholder="Default values"
                                required={false}
                                appendIcon="fa-solid fa-trash"
                                onClick={() => arrayHelpersParamValues.remove(valuesIndex)}
                                onPaste={(e) => {
                                  handlePaste(arrayHelpersParamValues, e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    <div
                      onClick={() => { arrayHelpersParamValues.push({ value: '', }); }}
                      className="w-100 mt-2 flex-d align-items-center-content-center btn btn-icon btn-active-light btn-active-color-primary "
                    >
                      <span className="svg-icon svg-icon-1">
                        <i className="bi bi-plus fs-2x " />
                        Add value
                      </span>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

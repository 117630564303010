/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { type FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { AsideMenu } from './AsideMenu';
import { useGetTenantLogoByAccessTokenQuery } from '../../../../app/views/tenant/api/api';
import { getUserData } from '../../../../app/utility/Utils';
import styles from './Aside.module.scss';
import AsideMenuBiHubStatus from './AsideMenuBiHubStatus';

const AsideDefault: FC = () => {
  const { config, classes, } = useLayout();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const { aside, } = config;
  const userData = getUserData();

  // If the user is a tenant, get the tenant logo
  const { data, isLoading, } = useGetTenantLogoByAccessTokenQuery(userData.userId, {
    skip: userData?.isHost,
  });

  if (!userData) {
    return null;
  }

  const minimize = () => {
    asideRef.current?.classList.add('animating');
    setTimeout(() => {
      asideRef.current?.classList.remove('animating');
    }, 300);
  };

  return (
    <div
      id="kt_aside"
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      ref={asideRef}
    >
      <div
        className={clsx(
          'aside-logo flex-column-auto border-bottom',
          !userData?.isHost && 'bg-white border-primary border-5'
        )}
        id="kt_aside_logo"
      >
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
          <Link to="/">
            {userData?.isHost
? (
  <img
    alt="Logo"
    className="h-15px logo"
    src={toAbsoluteUrl('/media/logos/Logo_BusinessInsight_Light.png')}
              />
            )
: (
              !isLoading &&
                            (!data
? (
  <img
    alt="Logo"
    className="h-15px logo"
    src={toAbsoluteUrl('/media/logos/Logo_BusinessInsight_Dark.png')}
                              />
                            )
: (
  <img
    alt="Logo"
    className={clsx('logo', styles.asideLogo)}
    src={`data:image/jpg;base64, ${data}`}
                              />
                            ))
            )}
          </Link>
        </div>

        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className={clsx(
              'btn btn-icon w-auto px-0 aside-toggle',
              userData.isHost ? 'btn-active-color-white ' : 'btn-active-color-black'
            )}
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
            onClick={minimize}
          >
            <KTSVG path="/media/icons/duotune/arrows/arr080.svg" className="rotate-180" />
          </div>
        )}
      </div>

      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      {!userData?.isHost &&
      <AsideMenuBiHubStatus userId={userData.userId} />}

      {!userData?.isHost
? (
  <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
    <Link to="/faq">
      <div
        className="btn btn-custom btn-primary w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="Check out the frequently asked questions"
            >
        <span className="btn-label">FAQ</span>
      </div>
    </Link>

  </div>
      )
: null}

    </div>
  );
};

export { AsideDefault };

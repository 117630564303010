import { emptySplitApi } from '../../../../utility/emptySplitApi';
import { type SsrsReport } from './types';
import { Ssrs_Host_Route } from '../../../../constants/routes';

const tenantApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getSsrsReports: build.query({
      query: (id: number) => `${Ssrs_Host_Route}Editable/${id}`,
      transformResponse: (rawResult: { data: { totalCount: number; items: SsrsReport[] } }) => rawResult.data,
      providesTags: ['Ssrs'],
    }),
    getSsrsById: build.query({
      query: (id) => `${Ssrs_Host_Route}editable/${id}`,
      transformResponse: (rawResult: { data: SsrsReport }) => rawResult.data,
      providesTags: ['Ssrs'],
    }),
    addNewSsrsReport: build.mutation({
      query (data: SsrsReport) {
        return {
          url: Ssrs_Host_Route,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Ssrs'],
    }),
    EditSsrsReport: build.mutation({
      query (data: SsrsReport) {
        return {
          url: Ssrs_Host_Route,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Ssrs'],
    }),
    deleteSsrsReport: build.mutation({
      query: (id) => ({
        url: Ssrs_Host_Route + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ssrs'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteSsrsReportMutation,
  useGetSsrsReportsQuery,
  useGetSsrsByIdQuery,
  useAddNewSsrsReportMutation,
  useEditSsrsReportMutation,
} = tenantApi;

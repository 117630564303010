import Select from 'antd/es/select';
import { useParams } from 'react-router';
import { useGetAvailableDimTablesQuery } from '../api/api';
import { CHOSEN_TABLE_DEFAULT } from '../api/const';

function AvailableDimTables (onchange: (e: any) => Promise<void>): React.ReactNode {
  const params = useParams();
  const { data, isLoading, } = useGetAvailableDimTablesQuery(params.id);

  return (
    <div>
      <Select
        style={{ width: 200, }}
        onChange={async (choice) => { await onchange(choice); }}
        className="basic-single "
        defaultValue={CHOSEN_TABLE_DEFAULT}
        loading={isLoading}
        options={data}
      />
    </div>
  );
}

export default AvailableDimTables;

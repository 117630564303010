import {
  forwardRef, memo, useEffect, useImperativeHandle, useState
} from 'react';
import { type ICellEditorParams } from 'ag-grid-community';
import DatePicker from 'antd/es/date-picker';
import dayjs, { type Dayjs } from 'dayjs';
import * as React from 'react';
import { CheckForValidDateAndRetunDayjs, CheckForValidDateAndReturnFormat } from './DateFormatter';
import { dateFormats } from '../../views/importForms/api/types';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

const DateTimePicker = memo(forwardRef((props: ICellEditorParams, ref) => {
  const [open, setOpen] = useState(false);
  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE) {
      startValue = '';
    } else if (props.eventKey) {
      startValue = props.eventKey;
      highlightAllOnFocus = false;
    } else {
      startValue = props.value;
      if (props.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState<Dayjs | null>(CheckForValidDateAndRetunDayjs(initialState.value));

  const onChange = (value: Dayjs | null) => {
    setValue(value);
  };

  useImperativeHandle(ref, () => ({
    getValue () {
      return CheckForValidDateAndReturnFormat(value!, 'YYYY-MM-DD');
    },
  }));

  useEffect(() => {
    setOpen(true);
  }, []);

  async function sleep (ms: number) {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onKeyDown = async (event: any) => {
    setOpen(true);
    // Hack fix where event.target.value is a key press behind because DatePicker is also mutating state
    await sleep(50);

    const parsed = dayjs(event.target.value, dateFormats, true);
    if (parsed.isValid()) {
      setValue(parsed);
    }
  };

  const onOpenChange = (status: boolean) => {
    if (!status) {
      setOpen(false);
    }
  };

  return (
    <DatePicker
      style={{
        width: '100%',
        height: '100%',
      }}
      value={value}
      onKeyDown={onKeyDown}
      onChange={onChange}
      format="YYYY-MM-DD"
      open={open}
      onOpenChange={onOpenChange}
      placeholder="YYYY-MM-DD"
    />

  );
}));

export default DateTimePicker;

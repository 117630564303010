import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { AsideMenuHost } from './AsideMenuHost';
import { AsideMenuTenant } from './AsideMenuTenant';
import { DrawerComponent, ScrollComponent, ToggleComponent } from '../../../assets/ts/components';
import { getUserData } from '../../../../app/utility/Utils';

interface Props {
  asideMenuCSSClasses: string[];
}

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses, }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { pathname, } = useLocation();
  const userData = getUserData();

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let SideMenu;

  if (userData?.isHost) {
    SideMenu = <AsideMenuHost />;
  } else {
    SideMenu = <AsideMenuTenant />;
  }

  return (
    <div
      id="kt_aside_menu_wrapper"
      ref={scrollRef}
      className="hover-scroll-overlay-y my-5 my-lg-5"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-offset="0"
    >
      <div
        id="#kt_aside_menu"
        data-kt-menu="true"
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-link-hover-state-white menu-active-bg-white menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        {SideMenu}
      </div>
    </div>
  );
};

export { AsideMenu };

import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SignalRContext, type SignalRMessage } from './SignalRProvider';
import { availableForSignalRTagInvalidation, emptySplitApi } from '../emptySplitApi';
import AppNotification from '../notifications/AppNotifications';

function SignalRConsumer () {
  const { addNotificationListener, removeNotificationListener, } = useContext(SignalRContext); // Access the addNotificationListener from the context
  const dispatch = useDispatch();

  useEffect(() => {
    const notificationCallback = ({ type, message, description, }: SignalRMessage) => {
      if (!message) {
        return;
      }

      if (type === 'success') {
        AppNotification.success(message, description, 0);
      } else if (type === 'error') {
        AppNotification.error(message, description, 0);
      } else {
        AppNotification.warning(message, description, 0);
      }
    };

    const baseQueryTagsInvalidatorCallback = ({ messageList, }: SignalRMessage) => {
      if (!messageList) {
        return;
      }

      // Check each tag if it's available for SignalR invalidation
      const invalidTags = messageList.filter((tag) => !availableForSignalRTagInvalidation.includes(tag));

      if (invalidTags.length) {
        console.error('These tags are not available for SignalR invalidation:', invalidTags);
        return;
      }

      dispatch(emptySplitApi.util.invalidateTags(messageList));
    };

    addNotificationListener('PipelineFinished', notificationCallback);
    addNotificationListener('TagInvalidate', baseQueryTagsInvalidatorCallback);

    // Clean up the listener when the component unmounts
    return () => {
      removeNotificationListener('PipelineFinished', notificationCallback);
      removeNotificationListener('TagInvalidate', baseQueryTagsInvalidatorCallback);
    };
  }, []);

  return null;
}

export default SignalRConsumer;

import {
  useCallback, useMemo, useState
} from 'react';
import TimePicker from 'antd/es/time-picker';
import dayjs, { type Dayjs } from 'dayjs';
import { useListView } from '../../../TenantListView';
import { useGetPipelineTransformedQuery, useUpdatePipelineMutation } from '../../../../bi-hub/api/api';
import { Message, RtkErrorMessage } from '../../../../../utility/notifications/Message';
import AgGridHubTable from './AgGridHubTable';
import { type BiHubPipelineDto, type BiHubPowerBiDatasetPutDto, type BiHubTaskPutDto } from '../../../../bi-hub/api/types';
import AppFormLabel from '../../../../components/forms/AppFormLabel';
import { convertToSeconds, secondsToDayjs } from '../../../../../utility/Utils';
import AppNotification from '../../../../../utility/notifications/AppNotifications';

function verifyTasks(tasks: BiHubTaskPutDto[]): boolean {
  // Check if all tasks have valid properties
  for (const task of tasks) {
    const errors: string[] = [];
    if (!task.action) {
      errors.push("Invalid or missing 'name' property.");
    }
    if (!task.action) {
      errors.push("Invalid or missing 'action' property.");
    }
    if (!task.type) {
      errors.push("Invalid or missing 'type' property.");
    }
    if (task.runParallel === undefined) {
      errors.push("Invalid or missing 'runParallel' property, contact the system administrator.");
    }

    if (errors.length > 0) {
      void Message('Validation error!', errors[0], 'error', {});
      return false;
    }
  }

  return true;
}

function verifyDataset(datasets: BiHubPowerBiDatasetPutDto[]): boolean {
  // Create a set to track ids
  const idSet = new Set();

  // Check for duplicates
  for (const dataset of datasets) {
    if (idSet.has(dataset.powerBiReportId)) {
      void Message('Duplicate error!', `Duplicate id found: ${dataset.powerBiReportDisplayName}`, 'error', {});
      return false;
    }

    if (!dataset.powerBiReportId) {
      void Message('Validation error!', 'Invalid or missing \'dataset\' property.!', 'error', {});
      return false;
    }
    idSet.add(dataset.powerBiReportId);
  }

  // If reached here, no duplicates found
  return true;
}

const timeFormat = 'HH:mm';

function BiHubForm() {
  const { toggle, id, } = useListView();

  if (!id) {
    return null;
  }

  const { data, isLoading, } = useGetPipelineTransformedQuery(id);
  const [biHubPipelineDto, setBiHubPipelineDto] = useState<BiHubPipelineDto>();
  const [updatePipeline, { isLoading: pipelineUpdateLoading, }] = useUpdatePipelineMutation();
  const [isEnabledForTenant, setIsEnabledForTenant] = useState<boolean>(false);
  const [infoTextBiHub, setInfoTextBiHub] = useState<string>('');
  const [pipelineTenantRunInterval, setPipelineTenantRunInterval] = useState<Dayjs | null>();

  const handleSubmit = useCallback(
    async (updateData: { tasks: BiHubTaskPutDto[]; datasets: BiHubPowerBiDatasetPutDto[] }) => {
      if (updateData.tasks.length === 0 && updateData.datasets.length === 0) {
        void Message('Post error!', 'Tasks are empty', 'error', {});
        return false;
      }

      if (verifyTasks(updateData.tasks) && verifyDataset(updateData.datasets) && id) {
        await updatePipeline({
          payload: {
            tenantId: id,
            isEnabledForTenant,
            lastUpdatedExplanationText: infoTextBiHub,
            pipelineTenantRunIntervalInSeconds: convertToSeconds(pipelineTenantRunInterval),
            ...updateData,
          },
        })
          .unwrap()
          .then(() => {
            AppNotification.success('BI hub', 'Pipeline is updated!');
            toggle(null);
          })
          .catch(async (error) => {
            await RtkErrorMessage('Updating pipeline failed!', error);
          });
      }
    },
    [updatePipeline, id, toggle, infoTextBiHub, isEnabledForTenant, pipelineTenantRunInterval]
  );

  const onChange = (time: Dayjs | null) => {
    setPipelineTenantRunInterval(time);
  };

  useMemo(() => {
    if (data) {
      setBiHubPipelineDto(data);
      setIsEnabledForTenant(data.isEnabledForTenant);
      setInfoTextBiHub(data.lastUpdatedExplanationText);
      setPipelineTenantRunInterval(secondsToDayjs(data.pipelineTenantRunIntervalInSeconds ?? 0));
    }
  }, [data]);

  if (!data && isLoading) {
    return (<></>);
  }

  return (
    <div className="form w-100 h-100">
      <div className="row">
        <div className="col col-md-12">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              id="flexCheckDefault"
              type="checkbox"
              checked={isEnabledForTenant}
              onChange={(e) => { setIsEnabledForTenant(e.target.checked); }}
            />
            <label className="form-check-label">
              <div className="fw-bolder text-gray-800">Enable BI Hub for company</div>
            </label>
          </div>
        </div>

        <div className="col col-md-6">
          <AppFormLabel label="Minimal elapsed time before pipeline can be rerunned" required={false} />
          <div className="input-group">
            <TimePicker
              showNow={false}
              value={pipelineTenantRunInterval}
              onChange={onChange}
              defaultValue={dayjs('00:00', timeFormat)}
              format={timeFormat}
              size="large"
              disabled={!isEnabledForTenant}
            />
          </div>
        </div>

        <div className="col col-md-12">
          <AppFormLabel label="Dataset explanation text (Viewable for company)" required={false} />
          <div className="input-group">
            <input
              className="form-control"
              value={infoTextBiHub}
              onChange={(e) => {
                setInfoTextBiHub(e.target.value);
              }}
              disabled={!isEnabledForTenant}
            />
          </div>
        </div>
      </div>

      {biHubPipelineDto
        ? (
          <AgGridHubTable
            tenantId={id}
            initialData={biHubPipelineDto}
            cancel={toggle}
            loading={pipelineUpdateLoading}
            handleSubmit={(event) => {
              void handleSubmit(event);
            }}
          />
        )
        : (
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
    </div>
  );
};

export { BiHubForm };

import React, { useEffect, useState } from 'react';
import AppFormDropdown from '../../components/forms/AppFormDropdown';
import { type ViewGroupTypes } from '../view-group/api/types';
import { useGetDropdownQuery } from '../view-group/api/api';

interface Props {
    tenantId: string;
    viewGroupType: ViewGroupTypes;
}

export function ViewGroupDropdown ({ tenantId, viewGroupType, }: Props) {
  const [dropdownMap, setDropdownMap] = useState(new Map());

  const {
    data: dropDownData,
  } = useGetDropdownQuery(`Dropdown?tenantId=${tenantId}&type=${viewGroupType}`);

  useEffect(() => {
    if (dropDownData) {
      const map = new Map();
      map.set('', 'Select view-group');
      dropDownData.forEach((item) => {
        map.set(item.id, item.title);
      });

      setDropdownMap(map);
    }
  }, [dropDownData]);

  return (
    <div className="fv-row mb-5">
      <AppFormDropdown
        name="viewGroupId"
        optionsMap={dropdownMap}
        label="Select view-group"
        required
      />
    </div>
  );
}

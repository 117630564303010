import clsx from 'clsx';
import { type FC } from 'react';
import { type Row } from 'react-table';

interface Props {
  row: Row<any>;
  renderRowSubComponent?: (row: Row<any>) => JSX.Element;
}

const CustomRow: FC<Props> = ({ row, renderRowSubComponent, }) => (
  <>
    <tr className="bg-white" {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps()}
          className={clsx({ 'text-end text-gray-800 max-w-30px': cell.column.id === 'actions', })}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
    {renderRowSubComponent && row.isExpanded
      ? (
        <tr className="">
          <td className="p-0 ps-20" colSpan={20}>
            {renderRowSubComponent(row)}
          </td>
        </tr>
      )
      : null}
  </>

);

export { CustomRow };

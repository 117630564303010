import { Popover } from 'react-bootstrap';

export const popoverElementMapping = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Shortcuts</Popover.Header>
    <Popover.Body>
      <p className="badge badge-secondary">CTRL</p>
      {' '}
      +
      <p className="badge badge-secondary">Enter</p>
      Creates a new
      {' '}
      <strong>Row</strong>

      <br />

      <p className="badge badge-secondary">CTRL</p>
      {' '}
      +
      <p className="badge badge-secondary">Del</p>
      A row gets
      {' '}
      <strong>Deleted</strong>

      <br />

      <p className="badge badge-secondary">CTRL</p>
      {' '}
      +
      <p className="badge badge-secondary">Arrow Up</p>
      {' '}
      or
      <p className="badge badge-secondary">Arrow Down</p>
      Goes to the first
      {' '}
      <strong>Empty</strong>
      {' '}
      cell
    </Popover.Body>
  </Popover>
);

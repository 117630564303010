import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, type WithChildren } from '../../../helpers';
import { useLayout } from '../../core';
import IsRouteOrActionAllowed from '../../../../app/utility/IsRouteOrActionAllowed';

interface Props {
    to: string;
    title: string;
    icon?: string;
    fontIcon?: string;
    hasBullet?: boolean;
    permissions?: string[];
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  permissions,
}) => {
  const { pathname, } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config, } = useLayout();
  const { aside, } = config;

  if (!IsRouteOrActionAllowed.IsSomeRouteOrActionAllowed([...permissions ?? []])) {
    return null;
  }

  return (
    <div
      className={clsx('menu-item', { 'here show': isActive, }, 'menu-accordion')}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' &&
        <i className={clsx('fs-2  me-3', fontIcon, { 'text-white': isActive, })} />}
        <span className="menu-title fs-5">{title}</span>
        <span className="menu-arrow" />
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive, })}>
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };

export interface ImportForm {
  description: string;
  id?: number;
  data: Data[];
  saveToDb: boolean;
  executeStoredProcedure: boolean;
}

export interface Field {
  id: number;
  title: string;
  columnIdentifier: string;
  datatype: number;
  hasDefaultValues: boolean;
  defaultValues: DefaultValue[];
  sorting: number;
}

export interface DefaultValue {
  id: number;
  value: string;
}

export const dateFormats = [
  'D-M-YYYY',
  'DD-MM-YYYY',
  'DD-MM-YYYY HH:mm:ss',
  'DD-MM-YYYYTHH:mm:ss',

  'YYYY-M-D',
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss.mmm',

  'D/M/YYYY',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm:ss',
  'DD/MM/YYYYTHH:mm:ss',

  'YYYY/M/D',
  'YYYY/MM/DD',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY/MM/DDTHH:mm:ss',

  'MM-D-YYYY',
  'MM-DD-YYYY',

  'MM/DD-/YYYY',
  'MM/DD/YYYY'
];

export interface Data {
  id?: number;
  string1: string;
  string2: string;
  string3: string;
  string4: string;
  string5: string;
  string6: string;
  string7: string;
  string8: string;
  string9: string;
  string10: string;
  date1: string;
  date2: string;
  date3: string;
  date4: string;
  date5: string;
  numeric1: number;
  numeric2: number;
  numeric3: number;
  numeric4: number;
  numeric5: number;
  numeric6: number;
  numeric7: number;
  numeric8: number;
  numeric9: number;
  numeric10: number;
  numeric11: number;
  numeric12: number;
  numeric13: number;
  numeric14: number;
  numeric15: number;
  numeric16: number;
  numeric17: number;
  numeric18: number;
  numeric19: number;
  numeric20: number;
  integer1: number;
  integer2: number;
  integer3: number;
  integer4: number;
  integer5: number;
  integer6: number;
  integer7: number;
  integer8: number;
  integer9: number;
  integer10: number;
}

import { emptySplitApi } from '../../../../utility/emptySplitApi';
import { ViewGroups_Host_Route, ViewGroups_Tenant_Route } from '../../../../constants/routes';
import {
  type CreateViewGroupDto, type DropDown, type ViewGroup, type ViewGroups
} from './types';

const viewGroupApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getPowerBiViewGroupsTenant: build.query({
      query: () => `${ViewGroups_Tenant_Route}powerBiReportViewGroups`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data,
      providesTags: ['ViewGroups', 'PowerBi'],
    }),
    getPowerBiViewGroups: build.query({
      query: (tenantId) => `${ViewGroups_Host_Route}powerBiReportViewGroups/${tenantId}`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data.viewGroups,
      providesTags: ['ViewGroups', 'PowerBi'],
    }),
    getPowerBiPaginatedReportViewGroupsTenant: build.query({
      query: () => `${ViewGroups_Tenant_Route}PowerBiPaginatedReportViewGroups`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data,
      providesTags: ['ViewGroups', 'PowerBi'],
    }),
    getPowerBiPaginatedReportViewGroups: build.query({
      query: (tenantId) => `${ViewGroups_Host_Route}PowerBiPaginatedReportViewGroups/${tenantId}`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data.viewGroups,
      providesTags: ['ViewGroups', 'PowerBi'],
    }),
    getImportViewGroups: build.query({
      query: (tenantId) => `${ViewGroups_Host_Route}ImportFormViewGroups/${tenantId}`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data,
      providesTags: ['ViewGroups', 'ImportForm'],
    }),
    getImportViewGroupsTenant: build.query({
      query: () => `${ViewGroups_Tenant_Route}ImportFormViewGroups`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data,
      providesTags: ['ViewGroups', 'ImportForm'],
    }),
    getSsrsViewGroups: build.query({
      query: (tenantId) => `${ViewGroups_Host_Route}SsrsReportViewGroups/${tenantId}`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data.viewGroups,
      providesTags: ['ViewGroups', 'Ssrs'],
    }),
    getSsrsViewGroupsTenant: build.query({
      query: () => `${ViewGroups_Tenant_Route}SsrsReportViewGroups`,
      transformResponse: (rawResult: { data: { viewGroups: ViewGroup[] } }) => rawResult.data,
      providesTags: ['ViewGroups', 'Ssrs'],
    }),
    getDropdown: build.query({
      query: (dropDownRequestFilter: string) => ViewGroups_Host_Route + dropDownRequestFilter,
      transformResponse: (rawResult: { data: DropDown[] }) => rawResult.data,
      providesTags: ['ViewGroups'],
    }),
    addNewViewGroup: build.mutation({
      query (data: CreateViewGroupDto) {
        return {
          url: ViewGroups_Host_Route,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['ViewGroups'],
    }),
    editViewGroup: build.mutation({
      query (data: ViewGroups) {
        return {
          url: `${ViewGroups_Host_Route}Collection`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['ViewGroups'],
    }),
    deleteViewGroup: build.mutation({
      query: (id) => ({
        url: ViewGroups_Host_Route + id,
        method: 'DELETE',
      }),
      invalidatesTags: ['ViewGroups', 'PowerBi', 'ImportForm'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPowerBiViewGroupsQuery,
  useGetPowerBiPaginatedReportViewGroupsTenantQuery,
  useGetPowerBiPaginatedReportViewGroupsQuery,
  useGetPowerBiViewGroupsTenantQuery,
  useGetImportViewGroupsQuery,
  useGetImportViewGroupsTenantQuery,
  useGetSsrsViewGroupsQuery,
  useGetSsrsViewGroupsTenantQuery,
  useAddNewViewGroupMutation,
  useGetDropdownQuery,
  useDeleteViewGroupMutation,
  useEditViewGroupMutation,
} = viewGroupApi;

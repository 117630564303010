// ** React Imports
import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { StrictMode } from 'react';

// ** Utils
import PublicRoutes from './routes/public';
import PublicRoute from './components/publicRoute';
import { isObjEmpty } from '../utility/Utils';
import PrivateRoute from './components/PrivateRoute';
import BlankLayout from '../../_metronic/layout/BlankLayout';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { AuthLayout } from '../../_metronic/layout/AuthLayout';
import { App } from '../App';
import PrivateRoutes from './routes/private';
import { ErrorsPage } from '../views/errors/ErrorsPage';
import routeNames from './routeNames';

// ** Default Route
const DefaultRoute = '/auth/login';

const getLayout = {
  blank: <BlankLayout />,
  master: <MasterLayout />,
  auth: <AuthLayout />,
} as any;

const getApp = {
  app:
    <StrictMode>
      <App />
    </StrictMode>,
};

// ** Merge Routes
const Routes = [
  {
    path: '/',
    index: true,
    meta: { publicRoute: true, },
    element: <Navigate replace to={DefaultRoute} />,
  },
  ...PublicRoutes,
  ...PrivateRoutes,
  {
    path: routeNames.notAuthorized,
    meta: {
      layout: 'master',
    },
    element: <ErrorsPage />,
  }
];

const getRouteMeta = (route: any) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta, };
    }
    return {};
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout: any, defaultLayout: any) => {
  const LayoutRoutes = [] as any;

  if (Routes) {
    Routes.filter((route) => {
      const isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      const routeMeta = route.meta as any;
      if (
        (route.meta && routeMeta.layout && routeMeta.layout === layout) ||
        ((route.meta === undefined || routeMeta.layout === undefined) && defaultLayout === layout)
      ) {
        let RouteTag = PublicRoute;

        // ** Check for public or private route
        if (route.meta) {
          RouteTag = routeMeta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          route.element = <RouteTag route={route}>{route.element}</RouteTag>;
        }

        const Wrapper =
          // eslint-disable-next-line multiline-ternary
          isObjEmpty(route.element.props) && !isBlank
            ? // eslint-disable-next-line multiline-ternary
            MasterLayout
            : Fragment;

        route.element = (
          <Wrapper {...(!isBlank ? getRouteMeta(route) : {})}>
            <RouteTag route={route}>{route.element}</RouteTag>
          </Wrapper>
        );

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout: any) => {
  const defaultLayout = layout || 'master';
  const layouts = ['auth', 'master', 'blank'];

  const AllRoutes = [] as any;

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });

  const rootRoute = [] as any;
  rootRoute.push({
    element: getApp.app,
    children: AllRoutes,
  });

  return rootRoute;
};

export { DefaultRoute, Routes, getRoutes };

import { useFormikContext } from 'formik';
import AppFormLabel from './AppFormLabel';
import AppFormError from './AppFormError';
import AppFormInputButton from './AppFormInputButton';
import { objectByString } from '../../../utility/Utils';

interface AppTextInputProps {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  appendIcon?: string;
  onClick?: () => void;
  [x: string]: any;
  onPaste?: (pastedData: string) => void; // Add this line
}

function AppTextInput({
  name,
  label,
  placeholder,
  required = false,
  appendIcon,
  onClick,
  onPaste,
  ...otherProps
}: AppTextInputProps) {
  const {
    setFieldTouched, setFieldValue, values, errors, touched,
  } = useFormikContext();

  const handlePaste = (event: any) => {
    if (onPaste) {
      event.preventDefault();

      const clipboardData = event.clipboardData;
      const pastedData = clipboardData.getData('Text');

      onPaste(pastedData);
    }
  };

  return (
    <>
      {values && (
        <>
          <AppFormLabel label={label} required={required} />
          <div className="input-group">
            <input
              onPaste={handlePaste}
              className="form-control"
              placeholder={placeholder}
              value={objectByString(values, name) || ''}
              onBlur={() => { setFieldTouched(name); }}
              onChange={(event) => { setFieldValue(name, event.target.value); }}
              {...otherProps}
            />
            {appendIcon && <AppFormInputButton onToggleClick={onClick} toggleIcon={appendIcon} />}
          </div>
        </>
      )}
      {!values && (
        <>
          <AppFormLabel label={label} required={required} />
          <input
            className="form-control"
            placeholder="placeholder"
            onBlur={() => { setFieldTouched(name); }}
            onChange={(event) => { setFieldValue(name, event.target.value); }}
            {...otherProps}
          />
        </>
      )}
      <AppFormError
        visible={objectByString(touched, name)}
        error={objectByString(errors, name)}
      />
    </>
  );
}

export default AppTextInput;

import { type ColDef } from 'ag-grid-enterprise';
import { type CellStyle } from 'ag-grid-community/dist/lib/entities/colDef';
import { actionReplacement, DataTypesMap } from '../../bi-hub/api/types';
import GridButton from '../../components/grid/DeleteButtonRenderer';
import { Message } from '../../../utility/notifications/Message';
import CheckboxRenderer from '../../components/grid/CheckboxRenderer';
import { type PowerBIReportDropdownDto } from '../../content-manager/PowerBi/api/types';

const commonColumnProps = {
  editable: true,
  cellEditor: 'agTextCellEditor',
  cellStyle: (params: any) => {
    if (params.data.name !== '') {
      return {};
    }
    return { border: '2px solid', borderColor: 'red', } as CellStyle;
  },
};

export const columnsSequential: ColDef[] = [
  {
    field: 'drag',
    rowDrag: true,
    maxWidth: 40,
  },
  {
    headerName: 'Step',
    maxWidth: 80,
    field: 'NVT',
    valueGetter: (params) => params.node?.rowIndex! + 1,
  },
  {
    field: 'type',
    maxWidth: 140,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: Array.from(DataTypesMap.values()),
    },
  },
  {
    field: 'name',
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    field: 'action',
    headerName: actionReplacement,
    ...commonColumnProps,
  },
  {
    headerName: 'Can company call',
    field: 'isEnabledForTenant',
    maxWidth: 180,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params: any) => ({
      async clicked (field: any, checked: boolean) {
        params.node.data.isEnabledForTenant = checked;
        params.api.refreshCells({ force: true, });
      },
    }),
  },
  {
    field: '',
    maxWidth: 40,
    cellRenderer: GridButton,
    cellStyle: { padding: 0, backgroundColor: 'red', },
    cellRendererParams: (params: any) => ({
      async clicked (field: any) {
        const result = await Message('Confirm', 'Are you sure you want to delete this task?', 'warning');
        if (result.isConfirmed) {
          params.api.applyTransaction({ remove: [field], });
        }
      },
    }),
  }
];

export const columnsParallel: ColDef[] = [
  {
    field: 'drag',
    rowDrag: true,
    maxWidth: 40,
  },
  {
    headerName: 'Step',
    maxWidth: 80,
    field: 'NVT',
    valueGetter: '0',
  },
  {
    field: 'type',
    maxWidth: 140,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: Array.from(DataTypesMap.values()),
    },
  },
  {
    field: 'name',
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    field: 'action',
    headerName: actionReplacement,
    ...commonColumnProps,
  },
  {
    headerName: 'Can company call',
    field: 'isEnabledForTenant',
    maxWidth: 180,
    cellStyle: { padding: 0, },
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params: any) => ({
      async clicked (field: any, checked: boolean) {
        params.node.data.isEnabledForTenant = checked;
        params.api.refreshCells({ force: true, });
      },
    }),
  },
  {
    field: '',
    maxWidth: 40,
    cellRenderer: GridButton,
    cellStyle: { padding: 0, backgroundColor: 'red', },
    cellRendererParams: (params: any) => ({
      async clicked (field: any) {
        const result = await Message('Confirm', 'Are you sure you want to delete this task?', 'warning');
        if (result.isConfirmed) {
          params.api.applyTransaction({ remove: [field], });
        }
      },
    }),
  }
];

export const getColumnsPowerBi = (powerBiList: PowerBIReportDropdownDto[]): ColDef[] => {
  const columns: ColDef[] = [];

  columns.push(
    {
      field: 'type',
      valueGetter: () => 'Power BI refresh',
    },
    {
      headerName: 'Dataset name',
      field: 'powerBiReportDisplayName',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: powerBiList.map((value) => value.label),
      },
      valueSetter: (params) => {
        const selectedName = params.newValue;
        const matchedItem = powerBiList.find((v) => v.label === selectedName);
        if (matchedItem) {
          params.data.powerBiReportId = matchedItem.id;
        }

        params.data.powerBiReportDisplayName = selectedName; // Update the type field in the data with the new value

        return true;
      },
    },

    {
      headerName: 'Can company call',
      field: 'isEnabledForTenant',
      maxWidth: 180,
      cellStyle: { padding: 0, },
      cellRenderer: CheckboxRenderer,
      cellRendererParams: (params: any) => ({
        async clicked (field: any, checked: boolean) {
          params.node.data.isEnabledForTenant = checked;
          params.api.refreshCells({ force: true, });
        },
      }),
    },

    {
      field: '',
      maxWidth: 40,
      cellRenderer: GridButton,
      cellStyle: { padding: 0, backgroundColor: 'red', },
      cellRendererParams: (params: any) => ({
        async clicked (field: any) {
          const result = await Message('Confirm', 'Are you sure you want to delete this task?', 'warning');
          if (result.isConfirmed) {
            params.api.applyTransaction({ remove: [field], });
          }
        },
      }),
    }
  );

  return columns;
};

import axios from 'axios';
import jwtDefaultConfig from './jwtDefaultConfig';
import { refreshBrowserTabs } from '../../utility/Utils';

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig, };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor() {
    this.jwtConfig = { ...this.jwtConfig, };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = process.env.REACT_APP_API_BASE_URL;

        // ** Get token from localStorage
        let accessToken = this.getToken();

        if (config.useMasterTokenParam) {
          accessToken = this.getMasterToken();
          if (accessToken) config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        // ** If token is present add it to request's Authorization Header
        else if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        } else {
          accessToken = this.getMasterToken();
          if (accessToken) config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem(this.jwtConfig.storageMasterTokenKeyName);
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
        }
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getMasterToken() {
    return localStorage.getItem(this.jwtConfig.storageMasterTokenKeyName);
  }

  getSignalRToken() {
    return localStorage.getItem(this.jwtConfig.storageSignalRTokenKeyName);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  setTenantFavourite(...args) {
    return axios.put(this.jwtConfig.favouriteMasterUserEndpoint, ...args, {
      useMasterTokenParam: true,
    });
  }

  getTenantsForMasterUser() {
    return axios.get(this.jwtConfig.tenantForMasterUserMasterUserEndpoint, {
      useMasterTokenParam: true,
    });
  }

  isGoogleAuthenticatorEnabled() {
    return axios.get(this.jwtConfig.GetGoogleAuthenticatorSettingsEnabledEndpoint, {
      useMasterTokenParam: true,
    });
  }

  putGoogleAuthenticatorSettings() {
    return axios.put(
      this.jwtConfig.GoogleAuthenticatorSettingsEndpoint,
      {},
      { useMasterTokenParam: true },
    );
  }

  getGoogleAuthenticatorSettings() {
    return axios.get(this.jwtConfig.GoogleAuthenticatorSettingsEndpoint, {
      useMasterTokenParam: true,
    });
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  handleLogin(object) {
    if (object.twoFactorToken) {
      localStorage.setItem(this.jwtConfig.storageTwoFactorRememberToken, object.twoFactorToken);
    }
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, object.accessToken);
    localStorage.setItem(this.jwtConfig.storageSignalRTokenKeyName, object.encryptedAccessToken);
    localStorage.setItem(this.jwtConfig.storageUserDateKeyName, JSON.stringify(object.userData));

    refreshBrowserTabs();
  }

  handleMasterLogin(object) {
    if (object.twoFactorToken) {
      localStorage.setItem(this.jwtConfig.storageTwoFactorRememberToken, object.twoFactorToken);
    }
    localStorage.setItem(this.jwtConfig.storageMasterTokenKeyName, object.accessToken);

    refreshBrowserTabs();
  }

  setTenant(object) {
    localStorage.setItem(this.jwtConfig.storageUserDateKeyName, JSON.stringify(object.userData));
    localStorage.setItem(
      this.jwtConfig.storageImpersonateDataKeyName,
      JSON.stringify(object.impersonateData),
    );
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, object.accessToken);

    refreshBrowserTabs();
  }

  setPermissions(value) {
    localStorage.setItem(this.jwtConfig.storagePermissionsKeyName, JSON.stringify(value));
  }

  setMasterToken(value) {
    localStorage.setItem(this.jwtConfig.storageMasterTokenKeyName, value);
  }

  impersonate(...args) {
    return axios.post(this.jwtConfig.loginImpersonateHostEndpoint, ...args);
  }

  ImpersonatedAuthenticate(impersonationToken) {
    return axios.post(
      `${this.jwtConfig.loginImpersonatedImpersonatedAuthenticateHostEndpoint}?impersonationToken=${impersonationToken}`,
    );
  }

  downloadFile(id) {
    return axios.get(this.jwtConfig.downloadFileEndpoint + id, {
      responseType: 'blob',
    });
  }

  loginMaster(...args) {
    return axios.post(this.jwtConfig.loginMasterUserEndpoint, ...args);
  }

  forgotPassword(email) {
    return axios.get(this.jwtConfig.resetPasswordMasterUserEndpoint + email);
  }

  getMfaOptions(id) {
    return axios.get(this.jwtConfig.mfaOptionsMasterUserEndpoint + id);
  }

  sendTwoFactorAuthCode(...args) {
    return axios.post(this.jwtConfig.sendTwoAuthFactorMasterUserEndpoint, ...args);
  }

  resetPassword(...args) {
    return axios.post(this.jwtConfig.resetPasswordMasterUserEndpoint, ...args);
  }

  changePassword(...args) {
    return axios.post(this.jwtConfig.changePasswordMasterUserEndpoint, ...args);
  }

  addTenant(...args) {
    return axios.post(this.jwtConfig.test, ...args);
  }

  login(tenantId) {
    if (tenantId) {
      return axios.post(
        `${this.jwtConfig.loginUserEndpoint}?tenantId=${tenantId}`,
        {},
        { useMasterTokenParam: true },
      );
    }

    return axios.post(`${this.jwtConfig.loginUserEndpoint}`, {}, { useMasterTokenParam: true });
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }
}

import * as React from 'react';
import { useState } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import AppTextInput from '../../../components/forms/AppTextInput';
import FormButton from '../../../components/forms/AppFormButton';
import { type CreateViewGroupDto, type ViewGroupTypes } from '../api/types';
import { useAddNewViewGroupMutation } from '../api/api';
import { RtkErrorMessage } from '../../../../utility/notifications/Message';
import AppNotification from '../../../../utility/notifications/AppNotifications';

interface Props {
  verticalSorting: number;
  tenantId: string;
  type: ViewGroupTypes;
}

const createViewGroupSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Title is required'),

  tenantId: Yup.string().required('TenantId is required'),
  type: Yup.number().required('Type is required'),
  verticalSorting: Yup.number().required('VerticalSorting is required'),
});

export function AddViewGroupButton ({ verticalSorting, tenantId, type, }: Props) {
  const [addNewViewGroup] = useAddNewViewGroupMutation();
  const [isAddingViewGroup, setIsAddingViewGroup] = useState(false);

  const [initialViewGroupData] = useState<CreateViewGroupDto>({
    title: '',
    tenantId,
    type,
    verticalSorting,
  });

  const handleSubmit = async (data: CreateViewGroupDto) => {
    await addNewViewGroup(data)
      .unwrap()
      .then(() => {
        setIsAddingViewGroup(false);
        AppNotification.success('Viewgroup management', `Viewgroup ${data.title} is successfully created`);
      })
      .catch(async (error) => { await RtkErrorMessage(error, 'Error while adding new view-group'); });
  };

  const formik = useFormik({
    initialValues: initialViewGroupData,
    validationSchema: createViewGroupSchema,
    onSubmit: async () => { },
  });

  return (
    <>
      {isAddingViewGroup
? (
  <label
    className="btn btn-outline btn-outline-dashed btn-outline-default d-flex align-items-center mb-10"
    style={{ marginTop: '50px', }}
        >
    <Formik
      initialValues={initialViewGroupData}
      validationSchema={createViewGroupSchema}
      onSubmit={handleSubmit}
          >
      {({ handleSubmit, }) => (
        <form className="form w-100 h-100" onSubmit={handleSubmit} noValidate>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-8">
                <AppTextInput
                  type="text"
                  name="title"
                  placeholder=""
                  label="View-group title"
                  required
                      />
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={() => { setIsAddingViewGroup(false); }}
                      >
                  Cancel
                </button>
              </div>

              <div className="col-2">
                <FormButton
                  state={formik.isSubmitting}
                  type="submit"
                  text="Submit"
                  color="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => handleSubmit}
                      />
              </div>
            </div>
          </div>
        </form>
            )}
    </Formik>
  </label>
      )
: (
  <label
    className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
    style={{ marginTop: '50px', }}
    onClick={() => { setIsAddingViewGroup(true); }}
        >
    <span className="svg-icon svg-icon-3x me-5">
      <span className="svg-icon svg-icon-1">
        <i className="bi bi-plus fs-2x " />
      </span>
    </span>
    <span className="text-dark fw-bolder d-block fs-4">Add new view-group</span>
  </label>
      )}
    </>
  );
}

import * as React from 'react';
import { type FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import FormButton from '../../components/forms/AppFormButton';
import AppTextInput from '../../components/forms/AppTextInput';
import { ParameterFieldArray } from '../components/ParameterFieldArray';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import {
  useAddNewSsrsReportMutation,
  useDeleteSsrsReportMutation,
  useEditSsrsReportMutation,
  useGetSsrsByIdQuery
} from './api/api';
import { type SsrsReport } from './api/types';
import { DataTypesMap } from '../PowerBi/api/types';
import { ViewGroupDropdown } from '../components/ViewGroupDropdown';
import { useListView } from '../../../utility/ListView';
import AppNotification from '../../../utility/notifications/AppNotifications';

const createSsrsReportFormSchema = Yup.object().shape({
  reportPath: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Report path is required'),

  displayName: Yup.string()
    .required('Name is required'),

  viewGroupId: Yup.number().required('View-group is required!'),

  parameters: Yup.array().of(
    Yup.object()
      .nullable()
      .shape({
        name: Yup.string().min(3, 'Parameter name too short').required('Required'),
        dataType: Yup.number().required('Required'),
      })
  ),
});

const editSsrsReportFormSchema = Yup.object().shape({
  reportPath: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Report path is required'),

  displayName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Name is required'),

  parameters: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        dataType: Yup.number().required('Required'),
      })
    ),
});

const CreateEditForm: FC = () => {
  const params = useParams();
  const tenantId = params.id;
  const viewGroupType = 2;

  // If ID === null it means we are in edit mode
  const { id, toggle, } = useListView();
  const inEditMode = id != null;

  const [addNewSsrsReport] = useAddNewSsrsReportMutation();
  const [editSsrsReport] = useEditSsrsReportMutation();
  const [deleteSsrsReport] = useDeleteSsrsReportMutation();

  const { data, } = useGetSsrsByIdQuery(id, {
    skip: !inEditMode,
  });

  useEffect(() => {
    if (data) {
      setSsrsReportFormForEdit(data);
    }
  }, [data]);

  const [ssrsReportForEdit, setSsrsReportFormForEdit] = useState<SsrsReport>(
    data ?? {
      reportPath: '',
      displayName: '',
      parameters: [],
      tenantId: parseInt(tenantId!),
    }
  );

  const handleDelete = async () => {
    await deleteSsrsReport(id)
      .unwrap()
      .then(() => {
        toggle(null);
        AppNotification.success('SSRS report management', 'Report is successfully deleted');
      })
      .catch(async (error) => { await RtkErrorMessage('Deleting import form failed', error); });
  };

  const handleSubmit = async (data: SsrsReport) => {
    if (!inEditMode) {
      data.parameters = data.parameters?.map((i) => {
        delete i.sortId;
        return i;
      });

      await addNewSsrsReport(data)
        .unwrap()
        .then(() => {
          toggle(null);
          AppNotification.success(
            'SSRS report management',
            `Report ${data.displayName} is successfully created`
          );
        })
        .catch(async (error) => { await RtkErrorMessage('Creating import form failed', error); });
    } else {
      await editSsrsReport(data)
        .unwrap()
        .then(() => {
          toggle(null);
          AppNotification.success(
            'SSRS report management',
            `Report ${data.displayName} is successfully updated`
          );
        })
        .catch(async (error) => { await RtkErrorMessage('Editing import form failed', error); });
    }
  };

  return (
    <>
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title className="h4">
          {inEditMode ? 'Edit' : 'Create'}
          {' '}
          SSRS report
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={ssrsReportForEdit}
          validationSchema={inEditMode ? editSsrsReportFormSchema : createSsrsReportFormSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit, values, isSubmitting, isValid,
          }) => (
            <form className="form w-100 h-100" onSubmit={handleSubmit} noValidate>
              <div className="alert alert-dark" role="alert">
                <h1>Instructions</h1>
                <div>
                  <span className="m-list-timeline__text">
                    1: Navigeer naar
                    <a href="http://83.149.98.219/reports/browse/">
                      http://83.149.98.219/reports/browse/
                    </a>
                    {' '}
                  </span>
                </div>
                <div>
                  <span className="m-list-timeline__text">
                    2: Volg het rapport naar het pad en neem de route over.
                  </span>
                </div>
                <div>
                  <span className="m-list-timeline__text">
                    3: Een rapport dat te vinden is op:
                    {' '}
                    <strong>TestRapportagesMap/TestRapport123.</strong>
                    {' '}
                    heeft als
                    {' '}
                    <strong>Report path: </strong>
                    /TestRapportagesMap/TestRapport123
                  </span>
                </div>
                <div>
                  <span className="m-list-timeline__text">
                    4: De Display name is de naam die de eindgebruiker straks in het
                    rapportageoverzicht ziet.
                  </span>
                </div>
              </div>

              <div className="fv-row mb-5">
                <AppTextInput
                  type="text"
                  name="reportPath"
                  placeholder=""
                  label="Report path"
                  required
                />
              </div>

              <div className="fv-row mb-5">
                <AppTextInput
                  type="text"
                  name="displayName"
                  placeholder=""
                  label="Name"
                  required
                />
              </div>

              {!inEditMode && (
                <ViewGroupDropdown tenantId={tenantId!} viewGroupType={viewGroupType} />
              )}

              <div className="separator my-5" />
              <div className="form-text">Add parameters</div>

              <ParameterFieldArray
                nestedName="values"
                name="parameters"
                values={values}
                dataType={DataTypesMap}
              />

              <div className="text-end pt-5 d-flex flex-row-reverse justify-content-between">
                <div>
                  <button
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                    onClick={() => { toggle(null); }}
                    disabled={false}
                  >
                    Cancel
                  </button>

                  <FormButton
                    width="w-20"
                    type="submit"
                    text="Submit"
                    state={isSubmitting}
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    onClick={() => handleSubmit}
                  />
                </div>

                {inEditMode && (
                  <div className="me-3">
                    <FormButton
                      width="w-20"
                      type="reset"
                      text="Delete"
                      state={false}
                      color="danger"
                      disabled={false}
                      title="Are you sure you want to delete this SSRS report?"
                      onClick={handleDelete}
                    />
                  </div>
                )}
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </>
  );
};

export { CreateEditForm };

import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { ViewGroupItemComponent } from './viewGroupItemComponent';
import { type ViewGroupItem } from '../../api/types';

interface SortableViewGroupItemProps {
    viewGroupItem: ViewGroupItem;
    containerId?: string;
    id: string;
    index?: number;
    handle: boolean;
    disabled?: boolean;

    style: (args: any) => React.CSSProperties;

    getIndex?: (id: string) => number;

    renderItem: () => React.ReactElement;

    wrapperStyle: ({ index, }: { index: number }) => React.CSSProperties;
}

export function SortableViewGroupItem ({
  viewGroupItem,
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
}: SortableViewGroupItemProps) {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <ViewGroupItemComponent
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      viewGroupItem={viewGroupItem}
      handle={handle}
      index={index}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
}

function useMountStatus () {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => { setIsMounted(true); }, 500);

    return () => { clearTimeout(timeout); };
  }, []);

  return isMounted;
}

import { useEffect, useState } from 'react';
import { KTSVG, useDebounce } from '../../../../_metronic/helpers';

interface Props {
  placeholder: string;
  onChange: (state: string) => void;
}

function SearchComponent ({ placeholder, onChange, }: Props) {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    onChange(value);
  }, [debouncedValue]);

  const searchItem = (targetValue: string) => {
    setValue(targetValue);
  };

  return (
    <div className="card-title">
      <div className="d-flex align-items-center position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-250px ps-14"
          placeholder={placeholder}
          value={value}
          onChange={(e) => { searchItem(e.target.value); }}
        />
      </div>
    </div>
  );
}

export { SearchComponent };
